import useGenericService from '../../../hooks/useGenericService';
import { ApiResources } from '../../../Models/Api';
import type { IFindLotesEmbarqueByFilter } from '../domain/dtos/IFindLotesEmbarqueByFilter';
import type { ILotesPorTransportadoraRepository } from '../domain/repositories/ILotesPorTransportadoraRepository';
import type { LotePorTransportadoraDtoResponse } from '../types/LotePorTransportadoraDtoResponse';

export class LotesPorTransportadoraRepository
    implements ILotesPorTransportadoraRepository
{
    private service = useGenericService().getService(
        ApiResources.LoteEmbarquePorTransportadora
    );

    async findLotesEmbarqueByDocumentoTransportadora(
        param: IFindLotesEmbarqueByFilter
    ): Promise<LotePorTransportadoraDtoResponse[]> {
        let endpoint = `${param.documento}`;

        endpoint += '?';

        if (param?.lote && param.lote.trim().length > 0) {
            endpoint += `lote=${param.lote}&`;
        }
        if (param.status.trim().length > 0) {
            endpoint += `status=${param.status}&`;
        }
        endpoint += 'sort=id,';
        if (param.sort.trim().length > 0) {
            endpoint += `${param.sort}&`;
        } else {
            endpoint += 'desc&';
        }
        if (param.page) {
            endpoint += `page=${param.page}&`;
        }
        if (param.size) {
            endpoint += `size=${param.size}&`;
        }

        const resultado = await this.service.api.get(endpoint);

        param.setTotal(resultado.data.totalElements);

        return resultado.data.content;
    }

    async findLoteByFiltro(
        param: IFindLotesEmbarqueByFilter
    ): Promise<LotePorTransportadoraDtoResponse[]> {
        let endpoint = `${param.documento}?`;

        // Verifica se o tipo de filtro e o texto do filtro foram informados
        if (param.filtroTipo?.trim() && param.filtroTexto?.trim()) {
            endpoint += `filtroTipo=${encodeURIComponent(
                param.filtroTipo
            )}&filtroTexto=${encodeURIComponent(param.filtroTexto)}&`;
        }
        // Verifica se o filtro de status foi informado
        if (param.status?.trim()) {
            endpoint += `status=${encodeURIComponent(param.status)}&`;
        }

        // Adiciona a ordenação
        const sortDirection = param.sort?.trim()
            ? encodeURIComponent(param.sort)
            : 'desc';
        endpoint += `sort=id,${sortDirection}&`;

        // Adiciona paginação, se existir
        if (param.page !== undefined) {
            endpoint += `page=${param.page}&`;
        }

        if (param.size !== undefined) {
            endpoint += `size=${param.size}&`;
        }

        const resultado = await this.service.api.get(endpoint);

        // Define o total de elementos
        param.setTotal(resultado.data.totalElements);

        return resultado.data.content;
    }

    async findLoteTransportadoraById(
        id: number
    ): Promise<LotePorTransportadoraDtoResponse> {
        const resultado = await this.service.api.get(
            `/buscar-lote-transportadora/${id}`
        );

        return resultado.data;
    }
}
