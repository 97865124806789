import { Button, Grid, TextField, Tooltip, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { styles } from '../OrdemColetaForm/Styles';
import ModalAnexarTicket from './components/ModalAnexarTicket';
import { IArquivoEncoded } from '../../../domain/entities/IArquivoEncoded';
import useGenericService from '../../../../../hooks/useGenericService';
import { ApiResources } from '../../../../../Models/Api';
import { processFileDownload } from '../../../../../utils/processarArquivo';

type FormProps = {
    disabled?: boolean;
    enableDownloadAndDelete?: boolean;
};

export function EnviarTicketDescargaInput({ disabled = false, enableDownloadAndDelete = false }: FormProps) {
    const { control, watch, getValues, setValue, reset } = useFormContext();

    const [mostrarModalAnexarTicket, setMostrarModalAnexarTicket] =
        useState<boolean>(false);

    const { getService } = useGenericService();

    const ticketName = watch('ticketDescarga.nome');

    async function handleBaixarDocumento(arquivo: IArquivoEncoded) {
        const anexo = arquivo?.anexo;
        if (anexo) {
			const service = getService(ApiResources.Anexo);
			const response = await service.api.get("/download", {
				params: {
					id: anexo?.fmId,
				},
				responseType: "arraybuffer",
			});
			processFileDownload(
				response.data,
				response.headers["content-disposition"],
                anexo?.mimeType,
                anexo?.fileName
			);
        }
    }

    return (
        <Grid container item spacing={2}>
            <Grid item xs={12} lg={3}>
                <Controller
                    name="ticketDescarga"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={''}
                    render={({
                        field: { ref, value, ...field },
                        fieldState,
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            disabled={true}
                            fullWidth
                            required
                            value={ticketName ? ticketName : ''}
                            label="Ticket Descarga"
                            error={fieldState?.invalid}
                            helperText={
                                fieldState?.invalid &&
                                'O campo não pode ficar em branco'
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                />
            </Grid>
            {enableDownloadAndDelete ? (
                <>
                    <Grid
                        item
                        xs={12}
                        lg={0.5}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Tooltip
                            title="Baixar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="baixar"
                                onClick={() => {
                                    const ticket = getValues('ticketDescarga');
                                    if (ticket) {
                                        handleBaixarDocumento(ticket);
                                    }
                                }}
                            >
                                <DownloadIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Button
                            onClick={() => {
                                setValue('ticketDescarga', null);
                                reset({ ...getValues() });
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Excluir Ticket
                        </Button>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={12} lg={3}>
                        <Button
                            onClick={() => {
                                setMostrarModalAnexarTicket(true);
                            }}
                            disabled={disabled}
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Anexar Ticket
                        </Button>
                    </Grid>
                    {mostrarModalAnexarTicket && (
                        <>
                            <ModalAnexarTicket
                                open={mostrarModalAnexarTicket}
                                onClose={() => {
                                    setMostrarModalAnexarTicket(false);
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
