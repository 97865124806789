import {
    Alert,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import { ApiResources } from '../../../Models/Api';
import { StatusRequisicao } from '../../../Models/Usuario';
import useGenericService from '../../../hooks/useGenericService';
import { styles } from '../helpers/Styles';
import type { IOrdemColetaResponseDto } from '../../OrdemColeta/domain/dtos/IOrdemColetaResponseDto';
import { SpinnerOverlay } from '../../../Components/SpinnerOverlay/spinnerOverlay';

interface Props {
    ordemColeta: IOrdemColetaResponseDto;
    fecharClicado: () => void;
    aberto: boolean;
    onReprocessa: () => void;
}

export default function ModalDetalheErro({
    ordemColeta,
    fecharClicado,
    aberto = false,
    onReprocessa,
}: Props) {
    const [erros, setErros] = useState<any[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { getService } = useGenericService();

    const isMobile = useMediaQuery('(max-width: 600px)');

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    useEffect(() => {
        if (aberto) {
            setIsLoading(true);
            const carregarErros = async () => {
                const service = getService(
                    ApiResources.OrdemColetaMessageERP
                );

                try {
                    const resposta = await service.api.get(
                        `/${ordemColeta.id}`,{ params: { messageType: 'ERROR' }}
                    );
                    if (resposta.status === StatusRequisicao.OK) {
                        setErros(resposta?.data);
                        setIsLoading(false);
                    } else {
                        enqueueSnackbar(
                            'Não foi possível encontrar o registro',
                            {
                                variant: 'error',
                                onClick: () => {
                                    closeSnackbar();
                                },
                            }
                        );
                        setIsLoading(false);
                    }
                } catch (error) {
                    console.log(error);
                    setIsLoading(false);
                }
            };

            carregarErros();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aberto, ordemColeta, enqueueSnackbar]);
    
    function extractMessage(ex: string): string | null {
        if (ex) {
            return ex.replace("br.com.maxiconsystems.ia.BusinessException:", "");
        } 
        return null;
        
    }
    const getCorpoErros = () => {
        const elements: React.ReactElement[] = [];

        erros?.forEach((erro, index) => {
            elements.push(
                <Card sx={styles(isMobile).cardAlteracoes} key={`${erro?.id}${index}`}>
                    <CardContent>
                        <Alert
                            variant="outlined"
                            severity="info"
                            style={{ borderColor: '#FEB139' }}
                            icon={false}
                        >
                            <div style={{ alignItems: 'center' }}>
                                <div>
                                    <strong>Data da atualização:</strong>{' '}
                                    {format(
                                        parseISO(
                                            erro?.dataHoraCriacao.toString()
                                        ),
                                        'dd/MM/Y HH:mm',
                                        { locale: ptBR }
                                    ).toString()}{' '}
                                </div>
                                <div>
                                    <strong>Tipo de registro: </strong>
                                    Erro
                                </div>
                                <div>
                                    <strong>Descrição do erro: </strong>
                                    {extractMessage(erro?.message)}
                                </div>
                            </div>
                        </Alert>

                    </CardContent>
                </Card>
            );
        });

        return elements;
    };

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Log's de Erro</DialogTitle>
            <SpinnerOverlay isLoading={isLoading} />

                {erros?.length === 0 ? (
                    <Alert severity="info">Não há erros registrados</Alert>
                ) : (
                    <DialogContent>{getCorpoErros()}</DialogContent>
                )}
            <DialogActions>
                <Button
                    onClick={() => onReprocessa()}
                    color="primary"
                    autoFocus
                >
                    Reprocessar
                </Button>
                <Button onClick={() => fecharClicado()} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
