import useGenericService from '../../../hooks/useGenericService';
import { ApiResources } from '../../../Models/Api';
import type { IFilterDefault } from '../../../types/interfaces/IFilterDefault';
import type { PaginationResponse } from '../../../types/interfaces/PaginationResponse';
import type { LoteEmbarqueDtoResponse } from '../domain/dtos/LoteEmbarqueDtoResponse';
import type { IFindLotesEmbarqueByFilter } from '../domain/entities/IFindLotesEmbarqueByFilter';

import type { ILoteEmbarqueRepository } from '../domain/repositories/ILoteEmbarqueRepository';
import type { ILoteEmbarqueResponseDto } from '../dtos/ILoteEmbarqueResponseDto';

export class LoteEmbarqueRepository implements ILoteEmbarqueRepository {
    private serviceLoteEmbarque = useGenericService().getService(
        ApiResources.LoteEmbarque
    );

    async findPaginado(
        filter: IFilterDefault
    ): Promise<PaginationResponse<ILoteEmbarqueResponseDto>> {
        const resultado = await this.serviceLoteEmbarque.api.get('/listar', {
            params: filter,
        });

        return resultado.data.content;
    }

    async getLoteEmbarqueById(id: number): Promise<ILoteEmbarqueResponseDto> {
        const resultado = await this.serviceLoteEmbarque.api.get(`/${id}`);

        return resultado.data;
    }

    async getLoteEmbarqueByLoteEmbarqueTransportadoraId(
        id: number
    ): Promise<ILoteEmbarqueResponseDto> {
        const resultado = await this.serviceLoteEmbarque.api.get(
            `/lote-embarque-transportadora/${id}`
        );

        return resultado.data;
    }

     async findLoteByFiltro(
        param: IFindLotesEmbarqueByFilter
    ): Promise<LoteEmbarqueDtoResponse[]> {
        let endpoint = `${param.documento}?`;

        // Verifica se o tipo de filtro e o texto do filtro foram informados
        if (param.filtroTipo?.trim() && param.filtroTexto?.trim()) {
            endpoint += `filtroTipo=${encodeURIComponent(
                param.filtroTipo
            )}&filtroTexto=${encodeURIComponent(param.filtroTexto)}&`;
        }
        // Verifica se o filtro de status foi informado
        if (param.status?.trim()) {
            endpoint += `status=${encodeURIComponent(param.status)}&`;
        }

        // Adiciona a ordenação
        const sortDirection = param.sort?.trim()
            ? encodeURIComponent(param.sort)
            : 'desc';
        endpoint += `sort=id,${sortDirection}&`;

        // Adiciona paginação, se existir
        if (param.page !== undefined) {
            endpoint += `page=${param.page}&`;
        }

        if (param.size !== undefined) {
            endpoint += `size=${param.size}&`;
        }

        const resultado = await this.serviceLoteEmbarque.api.get(endpoint);

        // Define o total de elementos
        param.setTotal(resultado.data.totalElements);

        return resultado.data.content;
    }
}
