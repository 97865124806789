import { joiResolver } from '@hookform/resolvers/joi';
import {
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import DividerComp from '../../Components/Divider/DividerComp';
import InputCelular from '../../Components/Input/InputCelular';
import InputCpfCnpj, {
    maskCPForCNPJ,
} from '../../Components/Input/InputCpfCnpj';
import InputTelefone from '../../Components/Input/InputTelefone';
import SelectPerfilUsuario from '../../Components/Select/SelectPerfilUsuario';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import Util from '../../Helpers/Util';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import PerfilUsuario from '../../Models/PerfilUsuario';
import useGenericService from '../../hooks/useGenericService';
import { validacao } from './helpers/JoiValidators';
import { styles } from './helpers/Styles';
import { VisibilityOff, Visibility } from '@mui/icons-material';

export type CamposFormulario = {
    id?: number;
    nome: string;
    perfilUsuario: PerfilUsuario;
    telefone: string;
    celular: string;
    email: string;
    login: string;
    senha: string | null;
    trocarSenha: boolean;
    cpfCnpj?: string;
    integradoMaxys?: boolean;
    loginAd?: string;
};

interface Props {
    disabled?: boolean;
    usuario?: CamposFormulario;
    permitirSenhaVazia?: boolean;
    onSubmit?: (dadosFormulario: CamposFormulario) => {};
    isCriar?: boolean;
}

export default function Formulario({
    disabled = false,
    usuario,
    permitirSenhaVazia = false,
    onSubmit,
    isCriar = false,
}: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [trocarSenhaUsuario, setTrocarSenhaUsuario] =
        useState<boolean>(false);
    const [mensagemErroPadraoSenha, setMensagemErroPadraoSenha] =
        useState<string>();
    const [errorMessageBuscarClifor, setErrorMessageBuscarClifor] =
        useState<string>();
    const [camposDisabledBuscarCpf, setCamposDisabledBuscarCpf] =
        useState<boolean>(true);

    const [autorizacaoVincularUsuarioErp, setAutorizacaoVincularUsuarioErp] =
        useState<boolean>(false);

    const [autenticacaoAd, setAutenticacaoAd] = useState<boolean>(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const navigate = useNavigate();
    const { getService } = useGenericService();
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
        getFieldState,
        trigger,
        clearErrors,
    } = useForm<CamposFormulario>({
        resolver: joiResolver(validacao(permitirSenhaVazia)),
        criteriaMode: 'all',
    });

    const senha = watch('senha') as string;
    const senhaDirty = getFieldState('senha').isDirty;

    useEffect(() => {
        if (!senhaDirty) {
            setMensagemErroPadraoSenha(undefined);
            return;
        }

        const naoTemNumeroECaracteresEspeciais =
            !Util.temNumeroECaracteresEspeciais(senha);
        const naoTemLetraMaiuscula = !Util.temLetraMaiuscula(senha);

        if (naoTemNumeroECaracteresEspeciais || naoTemLetraMaiuscula) {
            setMensagemErroPadraoSenha(
                'A senha deve conter ao menos 1 número, 1 caractere especial e 1 letra maiúscula.'
            );
        } else {
            setMensagemErroPadraoSenha(undefined);
        }
    }, [senha, senhaDirty]);

    const carregarConfigGeral = async () => {
        const servicoConfigGeral = getService(ApiResources.ConfigGeral);
        const resposta = await servicoConfigGeral.api.get('/');

        setAutorizacaoVincularUsuarioErp(resposta.data?.vincular_clifor_erp);
        setAutenticacaoAd(resposta.data?.autenticacaoAd);
    };

    const preencherFormulario = (usuario: CamposFormulario) => {
        reset({
            nome: usuario.nome,
            perfilUsuario: usuario.perfilUsuario,
            telefone: usuario.telefone,
            celular: usuario.celular,
            email: usuario.email,
            login: usuario.login,
            cpfCnpj: maskCPForCNPJ(usuario.cpfCnpj as string),
            senha: usuario.senha ? usuario.senha : null,
            integradoMaxys: usuario.integradoMaxys ? false : true,
            loginAd: usuario.loginAd,
        });
        setCamposDisabledBuscarCpf(false);
    };

    useEffect(() => {
        carregarConfigGeral();

        if (usuario) {
            preencherFormulario(usuario);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuario]);

    useEffect(() => {
        clearErrors('cpfCnpj');
        getFieldState('cpfCnpj').isDirty && trigger('cpfCnpj');
    }, [watch('cpfCnpj')]);

    const buscarClifor = async () => {
        const value = watch('cpfCnpj') as string;

        setIsLoading(true);
        setErrorMessageBuscarClifor(undefined);

        if (!value) {
            trigger('cpfCnpj');
            setIsLoading(false);
            return;
        }

        if (errors.cpfCnpj) {
            setIsLoading(false);
            return;
        }

        try {
            const servico = getService(ApiResources.Usuario);
            const respostaApi = await servico.api.get(
                `/buscar-clifor-maxys/${limparDocumento(value)}`
            );

            if (respostaApi.data?.Driver) {
                reset({
                    nome: respostaApi?.data?.Driver?.Name,
                    telefone: respostaApi?.data?.Driver?.Phone,
                    celular: respostaApi?.data?.Driver?.CellPhone,
                    email: respostaApi?.data?.Driver?.Email,
                    cpfCnpj: maskCPForCNPJ(respostaApi?.data?.Driver?.Document),
                    integradoMaxys: true,
                });
            } else {
                setErrorMessageBuscarClifor(
                    'Documento não encontrado, cadastre um novo abaixo.'
                );
            }
        } catch (error) {
            setErrorMessageBuscarClifor(
                'Documento não encontrado, cadastre um novo abaixo.'
            );
            setIsLoading(false);
        }
        setCamposDisabledBuscarCpf(false);
        setIsLoading(false);
    };

    const limparDocumento = (documento: string) => {
        return documento.replace(/\D/g, '');
    };

    const submitForm = async (dadosFormulario: CamposFormulario) => {
        if (mensagemErroPadraoSenha !== undefined) return;

        if (onSubmit) {
            onSubmit({
                ...dadosFormulario,
                cpfCnpj: limparDocumento(dadosFormulario.cpfCnpj as string),
                trocarSenha: trocarSenhaUsuario,
                integradoMaxys: dadosFormulario.integradoMaxys ? false : true,
            });
        }
    };

    return (
        <SpinnerLoading isLoading={isLoading}>
            <Container>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(submitForm)}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="cpfCnpj"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, onChange, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputCpfCnpj
                                            {...field}
                                            inputRef={ref}
                                            value={field.value}
                                            margin="normal"
                                            autoComplete="new-password"
                                            disabled={
                                                !isCriar && !!usuario?.cpfCnpj
                                            }
                                            fullWidth
                                            autoFocus
                                            label="CPF/CNPJ Usuário"
                                            error={!!errors?.cpfCnpj}
                                            onChange={(event) => {
                                                onChange(event);
                                                setErrorMessageBuscarClifor(
                                                    undefined
                                                );
                                            }}
                                            helperText={
                                                errors
                                                    ? errors?.cpfCnpj?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        {isCriar && autorizacaoVincularUsuarioErp && (
                            <Grid item xs={12} lg={2}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    style={styles().button}
                                    size="large"
                                    onClick={buscarClifor}
                                >
                                    Buscar
                                </Button>
                            </Grid>
                        )}
                    </Grid>

                    {errorMessageBuscarClifor && (
                        <p style={styles().mensagemErrorStyle}>
                            {errorMessageBuscarClifor}
                        </p>
                    )}

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={5}>
                            <Controller
                                name="nome"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            autoComplete="off"
                                            disabled={
                                                (disabled ||
                                                    camposDisabledBuscarCpf) &&
                                                autorizacaoVincularUsuarioErp
                                            }
                                            inputProps={{ maxLength: 120 }}
                                            fullWidth
                                            label="Nome"
                                            error={!!errors?.nome}
                                            helperText={
                                                errors?.nome
                                                    ? errors?.nome.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={5} mt={'16px'}>
                            <Controller
                                name="perfilUsuario"
                                control={control}
                                defaultValue={{} as PerfilUsuario}
                                render={(props) => {
                                    return (
                                        <SelectPerfilUsuario
                                            {...props}
                                            options={[]}
                                            required
                                            fullWidth
                                            disabled={
                                                (disabled ||
                                                    camposDisabledBuscarCpf) &&
                                                autorizacaoVincularUsuarioErp
                                            }
                                            inputRef={props.field.ref}
                                            label="Perfil do Usuário"
                                            onChange={(_, perfilUsuario) =>
                                                props.field.onChange(
                                                    perfilUsuario
                                                )
                                            }
                                            error={!!errors.perfilUsuario}
                                            helperText={
                                                errors.perfilUsuario
                                                    ? (
                                                          errors.perfilUsuario as any
                                                      ).message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>

                    <DividerComp texto="Login" />

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4}>
                            <Controller
                                name="login"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            fullWidth
                                            inputProps={{ maxLength: 70 }}
                                            disabled={
                                                (disabled ||
                                                    camposDisabledBuscarCpf) &&
                                                autorizacaoVincularUsuarioErp
                                            }
                                            label="Usuário"
                                            error={!!errors.login}
                                            helperText={
                                                errors.login
                                                    ? errors.login.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Controller
                                name="senha"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            fullWidth
                                            label="Senha"
                                            disabled={
                                                (disabled ||
                                                    camposDisabledBuscarCpf) &&
                                                autorizacaoVincularUsuarioErp
                                            }
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            id="password"
                                            autoComplete="current-password"
                                            error={!!errors.senha}
                                            helperText={
                                                errors.senha
                                                    ? errors.senha.message
                                                    : ''
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={
                                                                handleClickShowPassword
                                                            }
                                                            onMouseDown={
                                                                handleMouseDownPassword
                                                            }
                                                            edge="end"
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        

                        {autenticacaoAd && (
                            <Grid item xs={12} lg={4}>
                                <Controller
                                    name="loginAd"
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => {
                                        return (
                                            <TextField
                                                {...field}
                                                inputRef={ref}
                                                margin="normal"
                                                fullWidth
                                                inputProps={{ maxLength: 70 }}
                                                disabled={
                                                    disabled ||
                                                    camposDisabledBuscarCpf
                                                }
                                                label="Login AD"
                                                error={!!errors.loginAd}
                                                helperText={
                                                    errors.loginAd
                                                        ? errors.loginAd.message
                                                        : ''
                                                }
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                        )}
                        {mensagemErroPadraoSenha && (
                            <p style={styles().mensagemErrorStyle}>
                                {mensagemErroPadraoSenha}
                            </p>
                        )}
                    </Grid>

                   

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={5}>
                            <FormGroup>
                                <FormControlLabel
                                    disabled={
                                        (disabled || camposDisabledBuscarCpf) &&
                                        autorizacaoVincularUsuarioErp
                                    }
                                    control={
                                        <Checkbox
                                            checked={trocarSenhaUsuario}
                                            onClick={() =>
                                                setTrocarSenhaUsuario(
                                                    !trocarSenhaUsuario
                                                )
                                            }
                                        />
                                    }
                                    label="Solicitar a alteração de senha no primeiro acesso"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <DividerComp texto="Contato" />

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={5}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            autoComplete="off"
                                            disabled={
                                                (disabled ||
                                                    camposDisabledBuscarCpf) &&
                                                autorizacaoVincularUsuarioErp
                                            }
                                            fullWidth
                                            inputProps={{ maxLength: 150 }}
                                            label="Email"
                                            error={!!errors?.email}
                                            helperText={
                                                errors?.email
                                                    ? errors?.email.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Controller
                                name="telefone"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputTelefone
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            autoComplete="off"
                                            disabled={
                                                (disabled ||
                                                    camposDisabledBuscarCpf) &&
                                                autorizacaoVincularUsuarioErp
                                            }
                                            fullWidth
                                            label="Telefone"
                                            error={!!errors.telefone}
                                            helperText={
                                                errors.telefone
                                                    ? errors.telefone.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={5}>
                            <Controller
                                name="celular"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputCelular
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            autoComplete="off"
                                            disabled={
                                                (disabled ||
                                                    camposDisabledBuscarCpf) &&
                                                autorizacaoVincularUsuarioErp
                                            }
                                            fullWidth
                                            label="Celular"
                                            error={!!errors?.celular}
                                            helperText={
                                                errors?.celular
                                                    ? errors?.celular.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} justifyContent="end">
                        {!disabled ? (
                            <Button
                                type="submit"
                                variant="contained"
                                style={styles().button}
                                size="large"
                            >
                                Salvar
                            </Button>
                        ) : (
                            <Button
                                onClick={() => {
                                    navigate(
                                        FrontEndPathResources.CadastroUsuario +
                                            '/alterar/' +
                                            usuario?.id
                                    );
                                }}
                                variant="contained"
                                style={styles().button}
                                size="large"
                            >
                                Alterar
                            </Button>
                        )}
                    </Grid>
                </form>
            </Container>
        </SpinnerLoading>
    );
}
