import {
    Card,
    CardContent,
    Chip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    type Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { styles } from './helpers/Styles';

import { format } from 'date-fns';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import { FrontEndPathResources } from '../../Models/Api';
import { RecursosPerfisEnum } from '../../Models/Usuario';
import type { IOrdemColetaResponseDto } from '../OrdemColeta/domain/dtos/IOrdemColetaResponseDto';
import { EStatusOrdemColeta } from '../OrdemColeta/domain/entities/EStatusOrdemColeta';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArticleIcon from '@mui/icons-material/Article';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useFetchUseCase } from '../../hooks/useFetchUseCase';
import ModalAnexosDocumentos from '../LotesPorTransportadora/pages/components/ModalAnexosDocumentos';
import type { IOrdemColetaRequestDto } from '../OrdemColeta/domain/dtos/IOrdemColetaRequestDto';
import type { FindAnexosDocumentosByOrdemColetaId } from '../OrdemColeta/domain/usecases/FindAnexosDocumentosByOrdemColetaId';
import type { PostOrdemColeta } from '../OrdemColeta/domain/usecases/PostOrdemColeta';
import type { PutArquivoOrdemColeta } from '../OrdemColeta/domain/usecases/PutArquivoOrdemColeta';
import { ModalHistoricoAlteracoesOrdemColeta } from './ModalHistoricoAlteracoesOrdemColeta';
import ModalDetalheErro from '../LotesPorTransportadora/pages/ModalDetalheErro';
import type { PostReprocessarOrdemColeta } from '../OrdemColeta/domain/usecases/PostReprocessarOrdemColeta';
import type { MensagemErroRequisicaoApi } from '../../Config/Api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableHead: {
            backgroundColor: '#EDF0FE',
            fontWeight: 'bold',
        },
    })
);

interface OrdemColetaProps {
    row: any;
    postOrdemColeta: PostOrdemColeta;
    putArquivoOrdemColeta: PutArquivoOrdemColeta;
    findAnexosDocumentosByOrdemColetaId: FindAnexosDocumentosByOrdemColetaId;
    postReprocessarOrdemColeta: PostReprocessarOrdemColeta;
}

export const OrdemColeta: React.FC<OrdemColetaProps> = ({
    row,
    postOrdemColeta,
    putArquivoOrdemColeta,
    findAnexosDocumentosByOrdemColetaId,
    postReprocessarOrdemColeta

}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { executePromise } = useFetchUseCase();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [ordemColeta, setOrdemColeta] = useState<IOrdemColetaResponseDto>();
    const [ordemColetaId, setOrdemColetaId] = useState<number>();
    const [dacteLiberaAdiantamento, setDacteLiberaAdiantamento] = useState<boolean>(false);
    const [quarteirizacao, setQuarteirizacao] = useState<boolean>(false);

    const [isReprocessando, setIsReprocessando] = useState(false);

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);
    
    const [mostrarModalDetalheErro, setMostrarModalDetalheErro] =
        useState<boolean>(false);

    const [mostrarOrdemColetaDetalheErro, setMostrarOrdemColetaDetalheErro] =
        useState<IOrdemColetaResponseDto>();

     const reprocessarOrdemColeta = async (
        ordemColeta: IOrdemColetaRequestDto
    ) => {
        setIsReprocessando(true);
        executePromise(
            () => postReprocessarOrdemColeta.execute(ordemColeta.id!),
            () => {
                enqueueSnackbar('Ordem de coleta reprocessada com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                setIsReprocessando(false);
            },
            (errors) => {
                if (errors?.length > 0) {
                    errors.map((error: MensagemErroRequisicaoApi) =>
                        enqueueSnackbar(error?.mensagem, {
                            variant: 'error',
                            onClick: () => {
                                closeSnackbar();
                            },
                        })
                    );
                } else {
                    enqueueSnackbar(
                        'Não foi possível reprocessar a ordem de coleta',
                        {
                            variant: 'error',
                            onClick: () => {
                                closeSnackbar();
                            },
                        }
                    );
                }

                setIsReprocessando(false);
            }
        );
    };

    function handleReprocessarOrdemColeta(
        ordemColeta: IOrdemColetaResponseDto
    ) {
        setMostrarOrdemColetaDetalheErro(ordemColeta);
        setMostrarModalDetalheErro(true);
    }


    const getStatusOrdemColetaFormatado = (
        ordemColeta: IOrdemColetaResponseDto
    ) => {
        switch (ordemColeta.statusOrdemColeta) {
            case EStatusOrdemColeta.AGENDADO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <AccessTimeIcon style={styles().iconStyle} />
                                {EStatusOrdemColeta.AGENDADO}
                            </div>
                        }
                        color="primary"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.ENVIADO_PARA_DOUBLECHECK:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <WarningAmberIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.ENVIADO_PARA_DOUBLECHECK}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );

            case EStatusOrdemColeta.PENDENTE_DE_DESCARGA:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <WarningAmberIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.PENDENTE_DE_DESCARGA}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.PENDENTE_DE_FATURAMENTO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <WarningAmberIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.PENDENTE_DE_FATURAMENTO}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.ENVIADO_MAXYS:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <WarningAmberIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.ENVIADO_MAXYS}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.FINALIZADO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.FINALIZADO}
                            </div>
                        }
                        color="primary"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.AGUARDANDO_GRE014:
                return (
                    <Chip
                        style={{...styles().chipStatus,  borderColor: '#133870'}}
                        label={
                            <div style={{...styles().divChip,  color: '#133870'}}>
                                <WarningAmberIcon style={{...styles().iconStyle, color: '#133870'}} />{' '}
                                {EStatusOrdemColeta.AGUARDANDO_GRE014}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.FINALIZADO_GRE014:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.FINALIZADO_GRE014}
                            </div>
                        }
                        color="primary"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.COM_ERRO:
                return (
                    <Tooltip
                        title="Reprocessar Ordem de Coleta"
                        placement="top"
                        disableInteractive
                    >
                        <Chip
                            style={styles().chipStatus}
                            aria-label="reprocessar-ordem-coleta"
                            onClick={() =>
                                handleReprocessarOrdemColeta(ordemColeta)
                            }
                            label={
                                <div style={styles().divChip}>
                                    <CancelIcon style={styles().iconStyle} />{' '}
                                    {EStatusOrdemColeta.COM_ERRO}
                                </div>
                            }
                            color="error"
                            variant="outlined"
                            disabled={isReprocessando}
                        />
                    </Tooltip>
                );
        }
    };

    const getTransportadora = (row: any, transportadoraId: number) => {
        if (row?.loteEmbarqueTransportadoras) {
            for (const transportadora of row.loteEmbarqueTransportadoras) {
                if (
                    transportadora.id &&
                    transportadora.id === transportadoraId
                ) {
                    return (
                        transportadora.transportadora.nome ||
                        transportadora.transportadora.nomeFantasia
                    );
                }
            }
        }
        return null;
    };

    const getRowBodyExpandedRow = (row: any, navigate: NavigateFunction) => {
        return row.ordemColetas.map((ordemColeta: IOrdemColetaResponseDto) => {
            return (
                <TableRow key={ordemColeta.id}>
                    <TableCell>{ordemColeta.id}</TableCell>
                    <TableCell>
                        {ordemColeta.dataHoraColeta &&
                            format(
                                new Date(ordemColeta.dataHoraColeta),
                                'dd/MM/Y HH:mm'
                            ).toString()}
                    </TableCell>
                    <TableCell>
                        {ordemColeta?.cavalo?.placa}
                    </TableCell>
                    <TableCell>
                        {getTransportadora(
                            row,
                            ordemColeta.loteEmbarqueTransportadoraId
                        )}
                    </TableCell>
                    <TableCell>
                        {`${ordemColeta?.peso
                            ?.toString()
                            .replace('.', ',')
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')} KG`}
                    </TableCell>
                    <TableCell>
                        {ordemColeta?.ordemColetaValidacaoDescarga?.pesoDestino
                            ? `${
                                  `${ordemColeta?.ordemColetaValidacaoDescarga?.pesoDestino
                                      ?.toString()
                                      .replace('.', ',')
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')} KG`
                              }`
                            : ''}
                    </TableCell>
                    <TableCell>
                        {getStatusOrdemColetaFormatado(ordemColeta)}
                    </TableCell>
                    <TableCell>
                        <Tooltip
                            title="Abrir anexos e documentos"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="abrir"
                                onClick={() => {
                                    if (dacteLiberaAdiantamento == false) {
                                        setDacteLiberaAdiantamento(ordemColeta.dacteLiberaAdiantamento)
                                    }
                                    if (row.loteEmbarque) {
                                        setQuarteirizacao(row.loteEmbarque.quarterizacao)
                                    }
                                    setOrdemColetaId(ordemColeta.id);
                                }}
                            >
                                <PictureAsPdfIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell>

                    <TableCell>
                        <PermissionGate
                            recurso={RecursosPerfisEnum.ORDEM_COLETA_VER}
                            redirect={''}
                        >
                            <Tooltip
                                title="Ver"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="ver"
                                    onClick={() => {
                                        navigate(
                                            `${FrontEndPathResources.OrdemColeta}/ver/${ordemColeta.id}`,
                                            { state: row }
                                        );
                                    }}
                                >
                                    <RemoveRedEyeIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={RecursosPerfisEnum.ORDEM_COLETA_VER}
                            redirect={''}
                        >
                            <Tooltip
                                title="Histórico de alterações"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="ver histórico de alterações"
                                    onClick={() => {
                                        setOrdemColeta(ordemColeta);
                                        setMostrarModalAuditoria(true);
                                    }}
                                >
                                    <ArticleIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                    </TableCell>
                </TableRow>
            );
        });
    };

    return (
        <>
            <Card style={styles().card}>
                <CardContent>
                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Nº Ordem
                                </TableCell>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Data/Hora de início
                                </TableCell>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Placa
                                </TableCell>
                                <TableCell
                                    width={80}
                                    className={classes.tableHead}
                                >
                                    Transportadora
                                </TableCell>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Peso origem
                                </TableCell>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Peso destino
                                </TableCell>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Status
                                </TableCell>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Anexos e Documentos
                                </TableCell>
                                <TableCell
                                    width={200}
                                    className={classes.tableHead}
                                >
                                    Ações
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getRowBodyExpandedRow(row, navigate)}
                        </TableBody>
                    </Table>
                </CardContent>
                {!row?.ordemColetas && (row?.ordemColetas?.length ?? 0) > 0 ? (
                    <Typography
                        sx={{ fontSize: 14, textAlign: 'center' }}
                        color="text.secondary"
                        gutterBottom
                        mb={3}
                    >
                        Não há ordens de coletas registradas
                    </Typography>
                ) : (
                    <> </>
                )}
            </Card>
            {mostrarModalAuditoria && ordemColeta && (
                <ModalHistoricoAlteracoesOrdemColeta
                    ordemColeta={ordemColeta}
                    aberto={mostrarModalAuditoria}
                    fecharClicado={() => {
                        setMostrarModalAuditoria(false);
                    }}
                />
            )}
            {ordemColetaId && (
                <ModalAnexosDocumentos
                    open={!!ordemColetaId}
                    onClose={() => setOrdemColetaId(undefined)}
                    ordemColetaId={ordemColetaId}
                    dacteLiberaAdiantamento={dacteLiberaAdiantamento}
                    quarteirizacao={quarteirizacao}
                    setDacteLiberaAdiantamento={setDacteLiberaAdiantamento}
                    putArquivoOrdemColeta={putArquivoOrdemColeta}
                    findAnexosDocumentosByOrdemColetaId={
                        findAnexosDocumentosByOrdemColetaId
                    }
                />
            )}
            {mostrarModalDetalheErro && mostrarOrdemColetaDetalheErro && (
                    <ModalDetalheErro
                        ordemColeta={mostrarOrdemColetaDetalheErro}
                        aberto={mostrarModalDetalheErro}
                        fecharClicado={() => {
                            setMostrarModalDetalheErro(false);
                        }}
                        onReprocessa={() => {
                            reprocessarOrdemColeta(
                                mostrarOrdemColetaDetalheErro
                            );
                            setMostrarModalDetalheErro(false);
                        }}
                    />
                )}
        </>
    );
};
