import { CSSProperties } from 'react';
import { MoonLoader } from 'react-spinners';

const overlayStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Garante que o loader fique acima de outros elementos
};

interface SpinnerOverlayProps {
    isLoading: boolean;
    color?: string;
    size?: number;
}

export function SpinnerOverlay({
    isLoading,
    color = '#465f75',
    size = 50,
}: SpinnerOverlayProps) {
    if (!isLoading) return null;

    return (
        <div style={overlayStyle}>
            <MoonLoader color={color} loading={isLoading} size={size} />
        </div>
    );
}
