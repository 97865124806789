import { PermissionGate } from '../../../Components/Navegacao/PermissionGate';
import { FrontEndPathResources } from '../../../Models/Api';
import { RecursosPerfisEnum } from '../../../Models/Usuario';
import { FindJuncaoByFilter } from '../domain/usecases/FindJuncaoByFilter';
import { GetSaldoLoteEmbarqueTransportadoraById } from '../domain/usecases/GetSaldoLoteEmbarqueTransportadoraById';
import { PostOrdemColeta } from '../domain/usecases/PostOrdemColeta';
import { OrdemColetaRepository } from '../infra/OrdemColetaRepository';
import Criar from '../pages/Criar';

export const OrdemColetaCriar: React.FC = () => {
    const ordemColetaRepository = new OrdemColetaRepository();

    const postOrdemColeta = new PostOrdemColeta(ordemColetaRepository);
    const getSaldoLoteEmbarqueTransportadoraById =
        new GetSaldoLoteEmbarqueTransportadoraById(ordemColetaRepository);

    const findJuncaoByFilter = new FindJuncaoByFilter(ordemColetaRepository);
    const bloqueioDivergenciaLocatarioQuarteirizado = false;

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.ORDEM_COLETA_CRIAR}
            redirect={FrontEndPathResources.OrdemColeta}
        >
            <Criar
                postOrdemColeta={postOrdemColeta}
                getSaldoLoteEmbarqueTransportadoraById={
                    getSaldoLoteEmbarqueTransportadoraById
                }
                findJuncaoByFilter={findJuncaoByFilter}
                bloqueioDivergenciaLocatarioQuarteirizado={bloqueioDivergenciaLocatarioQuarteirizado}
            />
        </PermissionGate>
    );
};
