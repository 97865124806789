import { Alert } from '@mui/material';
import type { AuditoriaCustom } from '../../../Models/Auditoria';
import type LoteEmbarqueTransportadora from '../../../Models/LoteEmbarqueTransportadora';
import { ItensAlterados } from './ItensAlterados';
import { useEffect, useState } from 'react';
import { StatusRequisicao } from '../../../Models/Usuario';
import { useSnackbar } from 'notistack';
import { ApiResources } from '../../../Models/Api';
import useGenericService from '../../../hooks/useGenericService';
import { SpinnerOverlay } from '../../../Components/SpinnerOverlay/spinnerOverlay';
import { Auditoria } from './Auditoria';

export function CorpoAuditoria({
    loteEmbarqueTransportadoraId,
}: {
    loteEmbarqueTransportadoraId: number;
}) {
    const [auditorias, setAuditorias] = useState<
        AuditoriaCustom<LoteEmbarqueTransportadora>[]
    >([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { getService } = useGenericService();

    useEffect(() => {
        const carregarAuditorias = async () => {
            setIsLoading(true);
            try {
                const resposta = await getService(
                    ApiResources.LoteEmbarquePorTransportadora
                ).api.get(`/auditoria/${loteEmbarqueTransportadoraId}`);

                if (resposta.status === StatusRequisicao.OK) {
                    setAuditorias(resposta?.data?.content);
                } else {
                    mostrarErro('Não foi possível encontrar o registro');
                }
            } catch (error) {
                console.error(error);
                mostrarErro('Erro ao carregar auditorias');
            } finally {
                setIsLoading(false);
            }
        };

        carregarAuditorias();
    }, []);

    const mostrarErro = (mensagem: string) => {
        enqueueSnackbar(mensagem, {
            variant: 'error',
            onClick: () => closeSnackbar(),
        });
    };

    if (!isLoading && auditorias.length === 0) {
        return <Alert severity="info">Não há alterações registradas</Alert>;
    }

    if (isLoading) {
        return <SpinnerOverlay isLoading={isLoading} size={36} />;
    }

    return (
        <>
            {!isLoading &&
                auditorias.map((auditoria, index) => (
                    <Auditoria
                        key={auditoria.entidade.id}
                        auditoria={auditoria}
                        index={index}
                    >
                        {index > 0 && (
                            <ItensAlterados
                                loteEmbarqueTransportadoraAnterior={
                                    auditorias[index - 1].entidade
                                }
                                loteEmbarqueTransportadoraAtual={auditoria.entidade}
                            />
                        )}
                    </Auditoria>
                ))}
        </>
    );
}
