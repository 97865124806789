import type { AxiosResponse } from 'axios';
import useGenericService from '../../../hooks/useGenericService';
import { ApiResources } from '../../../Models/Api';
import type { IFilterDefault } from '../../../types/interfaces/IFilterDefault';
import type { PaginationResponse } from '../../../types/interfaces/PaginationResponse';
import type { IFindOrdemColetaDoubleCheckByFilter } from '../../DoubleCheck/dtos/IFindOrdemColetaDoubleCheckByFilter';
import type { IFindJuncaoByFilterDto } from '../domain/dtos/IFindJuncaoByFilterDtoRequest';
import type { IFindJuncaoByFilterDtoResponse } from '../domain/dtos/IFindJuncaoByFilterDtoResponse';
import type { IOrdemColetaLancamento } from '../domain/dtos/IOrdemColetaLancamento';
import type { IOrdemColetaRequestDto } from '../domain/dtos/IOrdemColetaRequestDto';
import type { IOrdemColetaResponseDto } from '../domain/dtos/IOrdemColetaResponseDto';
import type { IOrdemColetaValidacaoDescarga } from '../domain/dtos/IOrdemColetaValidacaoDescarga';
import type { IPutArquivoOrdemColetaLancamento } from '../domain/dtos/IPutArquivoOrdemColetaLancamento';
import type { IOrdemColetaRepository } from '../domain/repositories/IOrdemColetaRepository';

export class OrdemColetaRepository implements IOrdemColetaRepository {
    private serviceOrdemColeta = useGenericService().getService(
        ApiResources.OrdemColeta
    );
    private serviceOrdemColetaLancamento = useGenericService().getService(
        ApiResources.OrdemColetaLancamento
    );
    private serviceOrdemColetaValidacaoDescarga =
        useGenericService().getService(
            ApiResources.OrdemColetaValidacaoDescarga
        );

    async findPaginado(
        filter: IFilterDefault
    ): Promise<PaginationResponse<IOrdemColetaResponseDto>> {
        const resultado = await this.serviceOrdemColeta.api.get('/listar', {
            params: filter,
        });

        return resultado.data.content;
    }

    async getOrdemColetaById(id: number): Promise<IOrdemColetaResponseDto> {
        const resultado = await this.serviceOrdemColeta.api.get(
            `/buscar/${id}`
        );

        return resultado.data;
    }

    async postOrdemColeta(ordemColeta: IOrdemColetaRequestDto): Promise<void> {
        await this.serviceOrdemColeta.api.post('/cadastrar', ordemColeta);
    }

    async putOrdemColeta(ordemColeta: IOrdemColetaRequestDto): Promise<void> {
        await this.serviceOrdemColeta.api.put(
            `/atualizar/${ordemColeta.id}`,
            ordemColeta
        );
    }

    async deleteOrdemColetaById(id: number): Promise<void> {
        await this.serviceOrdemColeta.api.delete(`/cancelar/${id}`);
    }

    async postReprocessarOrdemColeta(ordemColetaId: number): Promise<void> {
        await this.serviceOrdemColetaLancamento.api.post(
            `/reprocessar/${ordemColetaId}`
        );
    }

    async postReprocessarOrdemColetaQuarterizacao(ordemColetaId: number): Promise<void> {
        await this.serviceOrdemColeta.api.post(
            `/reprocessar-quarterizacao/${ordemColetaId}`
        );
    }

    async postReprocessarDescarga(ordemColetaId: number): Promise<void> {
        await this.serviceOrdemColetaValidacaoDescarga.api.post(
            `/reprocessar/${ordemColetaId}`
        );
    }

    async getSaldoLoteEmbarqueTransportadoraById(id: number): Promise<number> {
        const resultado = await this.serviceOrdemColeta.api.get(
            `/saldo-lote-embarque-transportadora/${id}`
        );

        return resultado.data;
    }

    async findJuncaoByFilter(
        filter: IFindJuncaoByFilterDto
    ): Promise<IFindJuncaoByFilterDtoResponse> {
        const resultado = await this.serviceOrdemColeta.api.post(
            '/validar-juncao',
            {
                ...filter,
            }
        );

        return resultado.data;
    }

    async postLancarDadosEmbarque(
        params: IOrdemColetaLancamento
    ): Promise<void> {
        await this.serviceOrdemColetaLancamento.api.post('/cadastrar', params);
    }

    async enviarDadosOrdemColetaProMaxysCasoArmazenJuncao(
        params: IOrdemColetaLancamento
    ): Promise<void> {
        await this.serviceOrdemColetaLancamento.api.post(
            '/enviar-erp-maxys',
            params
        );
    }

    async getLancarDadosEmbarqueById(
        id: number
    ): Promise<IOrdemColetaLancamento> {
        const resultado = await this.serviceOrdemColetaLancamento.api.get(
            `/buscar/por-ordem-coleta/${id}`
        );

        return resultado.data;
    }

    //#inicio_OrdemColetaValidacaoDescarga_Parte_III
    async buscarValidacaoDescargaPorIdOrdemColeta(
        IdOrdemColeta: number
    ): Promise<IOrdemColetaValidacaoDescarga> {
        const resultado = await this.serviceOrdemColeta.api.get(
            `/buscar/${IdOrdemColeta}`
        );
        return resultado.data;
    }

    async cadastrarValidacaoDescarga(
        validacaoDescarga: IOrdemColetaValidacaoDescarga
    ): Promise<void> {
        await this.serviceOrdemColetaValidacaoDescarga.api.post("/cadastrar", {
            ...validacaoDescarga,
            ordemColetaId: validacaoDescarga.idOrdemColeta,
        });
    }

    async editarValidacaoDescarga(
        id: number,
        validacaoDescarga: IOrdemColetaValidacaoDescarga
    ): Promise<void> {
        await this.serviceOrdemColetaValidacaoDescarga.api.put(
            `/atualizar/${id}`,
            validacaoDescarga
        );
    }

    async cancelarValidacaoDescarga(id: number): Promise<void> {
        await this.serviceOrdemColetaValidacaoDescarga.api.delete(
            `/cancelar/${id}`
        );
    }

    async restaurarValidacaoDescarga(
        id: number,
        validacaoDescarga: IOrdemColetaValidacaoDescarga
    ): Promise<void> {
        await this.serviceOrdemColetaValidacaoDescarga.api.put(
            `/restaurar/${id}`,
            validacaoDescarga
        );
    }
    //#fim_OrdemColetaValidacaoDescarga_Parte_III

    async putArquivoOrdemColeta(
        params: IPutArquivoOrdemColetaLancamento
    ): Promise<void> {
        await this.serviceOrdemColetaLancamento.api.put(
            `/arquivo/${params.ordemColetaId}`,
            params
        );
    }

    async findAnexosDocumentosByOrdemColetaId(
        ordemColetaId: number
    ): Promise<any> {
        const resultado = await this.serviceOrdemColetaLancamento.api.get(
            `/anexos-documentos/${ordemColetaId}`
        );

        return resultado.data;
    }
    async findOrdemColetaDoubleCheckByFiltros(filter: IFindOrdemColetaDoubleCheckByFilter): Promise<AxiosResponse>{
          const resultado = await this.serviceOrdemColeta.api.get(
            '/todas-double-check',
            {
                params: {
                    ...(filter?.filtroStatus && {filtroStatus: filter?.filtroStatus}),
                    ...(filter?.filtroTexto && {filtroTexto: filter?.filtroTexto}),
                    ...(filter?.status && {
                        status: filter?.status,
                    }),
                    page: filter?.page,
                    size: filter?.size,
                    sort: `${filter?.orderBy},${filter?.sort}`,
                },
            }
        );

        return resultado;
    };


}
