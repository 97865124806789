import { Button, Container, Grid } from '@mui/material';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import DividerComp from '../../Components/Divider/DividerComp';
import PageTitle from '../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import useGenericService from '../../hooks/useGenericService';
import { LotePorTransportadoraDtoResponse } from '../LotesPorTransportadora/types/LotePorTransportadoraDtoResponse';
import { IOrdemColetaResponseDto } from '../OrdemColeta/domain/dtos/IOrdemColetaResponseDto';
import { styles } from '../PerfilUsuario/helpers/Styles';
import CavaloCarretaForm from './components/CavaloCarretaForm';
import ClassificacaoForm from './components/ClassificacaoForm';
import ConfirmacaoModal from './components/ConfirmacaoModal';
import EmbarqueForm from './components/EmbarqueForm';
import MotoristaForm from './components/MotoristaForm';
import NotaFiscalForm from './components/NotaFiscalForm';
import ObservacoesForm from './components/ObservacoesForm';
import OrdemColetaForm from './components/OrdemColetaForm';
import StatusForm from './components/StatusForm';
import UsuarioResponsavelForm from './components/UsuarioResponsavelForm';
import { IDoubleCheck, IUsuario } from './entities/IDoubleCheck';
import { ILoteEmbarqueContratos } from './entities/INotaComContratos';
import { IOrdemColetaLancamentoDoubleCheck } from './entities/IOrdemColetaLancamentoDoubleCheck';
import VinculoContratoSemNotaForm from './components/VinculoContratoSemNotaForm';
import { EStatusOrdemColeta } from '../OrdemColeta/domain/entities/EStatusOrdemColeta';
import { EStatusDoubleCheck } from './entities/EStatusDoubleCheck';
import { useAuth } from '../../AuthProvider';

type FormProps = {
    disabled?: boolean;
};

export function DoubleCheck({ disabled = false }: FormProps) {
    const formDoubleCheck = useForm<IDoubleCheck>({
        criteriaMode: 'all',
    });

    const { id } = useParams<{ id: string }>();

    const { setValue, getValues, watch } = formDoubleCheck;

    const { getService } = useGenericService();

    const [loading, setLoading] = useState(true);

    const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const {usuarioLogado} = useAuth();
    

    const servicoOcl = getService(ApiResources.OrdemColetaLancamento);
    const servicoOc = getService(ApiResources.OrdemColeta);
    const servicoLet = getService(ApiResources.LoteEmbarquePorTransportadora);
    const servicoContrato = getService(ApiResources.LoteEmbarqueContrato);
    const servicoUsuario = getService(ApiResources.Usuario);

    const validaDoubleCheck = (ordemColeta: IOrdemColetaResponseDto) => {
        const responsavelDoubleCheck = ordemColeta.ordemColetaLancamento?.responsavelDoubleCheck;
        const statusOc = ordemColeta.statusOrdemColeta
        const statusDoubleCheck = ordemColeta.ordemColetaLancamento?.statusDoubleCheck
        if (statusOc == EStatusOrdemColeta.PENDENTE_DE_DESCARGA || statusOc === EStatusOrdemColeta.FINALIZADO || statusOc == EStatusOrdemColeta.FINALIZADO_GRE014)
            navigate(`${FrontEndPathResources.DoubleCheck}`);
            
        if (statusDoubleCheck == EStatusDoubleCheck.PENDENTE || statusDoubleCheck == EStatusDoubleCheck.LIBERADO || statusDoubleCheck == EStatusDoubleCheck.ERRO)
            navigate(`${FrontEndPathResources.DoubleCheck}`);
        if ( responsavelDoubleCheck === usuarioLogado?.login || !responsavelDoubleCheck ) {
            return ;
        }
        navigate(`${FrontEndPathResources.DoubleCheck}`);
    }

    useEffect(() => {
        const fetchData = async () => {
            const responseOc: AxiosResponse<IOrdemColetaResponseDto> =
                await servicoOc.api.get(`/buscar/${id}`);

            validaDoubleCheck(responseOc.data)            
            const responseLet: AxiosResponse<LotePorTransportadoraDtoResponse> =
                await servicoLet.api.get(
                    `/buscar-lote-transportadora/${responseOc.data.loteEmbarqueTransportadoraId}`
                );

            const responseContrato: AxiosResponse<ILoteEmbarqueContratos[]> =
                await servicoContrato.api.get(
                    `/listar/por-lote-embarque/${responseLet.data.loteEmbarque.id}`
                );

            const responseUsuarios: AxiosResponse =
                await servicoUsuario.api.get(
                    '/buscar-usuarios-responsaveis-double-check'
                );

            // Motorista e transportadora
            setValue('motorista', {
                transportadora: responseLet.data.transportadora.nome,
                ...responseOc.data.motorista,
            });

            // Cavalo
            setValue('cavalo', responseOc.data.cavalo);

            // Carretas
            if (responseOc.data.carretas)
                setValue('carretas', responseOc.data.carretas);

            // Ordem coleta
            setValue('coleta', {
                id: responseOc.data.id,
                peso: Number(responseOc.data.peso),
                produto: responseLet.data.produto.nome,
                lote: responseLet.data.lote,
            });

            // Classificação amostras
            if (responseOc.data.classificacaoAmostras)
                setValue(
                    'classificacaoAmostras',
                    responseOc.data.classificacaoAmostras
                );

            // Ticket
            if (responseOc.data.ordemColetaLancamento)
                setValue(
                    'ticket',
                    responseOc.data.ordemColetaLancamento
                        .ticketCaminhaoCarregado
                );

            // Laudo
            if (responseOc.data.ordemColetaLancamento)
                setValue('laudo', responseOc.data.ordemColetaLancamento.laudo);

            // Lista de Contratos de Origem do Lote
            responseContrato.data.map((contrato) => {
                contrato.permiteEditarTransgenia = contrato.transgenia == null;
            });
            if (responseContrato)
                setValue('listaContratos', responseContrato.data);

            if (
                responseOc.data.ordemColetaLancamento &&
                responseOc.data.ordemColetaLancamento.notasAnteriores
            )
                setValue(
                    'notas',
                    responseOc.data.ordemColetaLancamento.notasAnteriores
                );

            if (
                    responseOc.data.ordemColetaLancamento &&
                    responseOc.data.ordemColetaLancamento.contratosVinculadosSemNota
            )
                setValue(
                    'vinculosSemNota',
                    responseOc.data.ordemColetaLancamento.contratosVinculadosSemNota
                );    

            // Controle trocaNotaGo (trocaDeNotaEstadoDoGoias)
            if (
                responseOc.data.ordemColetaLancamento &&
                responseOc.data.ordemColetaLancamento.trocaNotaGo
        )
            setValue(
                'trocaDeNotaEstadoDoGoias',
                responseOc.data.ordemColetaLancamento.trocaNotaGo
            ); 


            if (responseOc.data.ordemColetaLancamento) {
                setValue(
                    'observacoes',
                    responseOc.data.ordemColetaLancamento.observacoes
                        ? responseOc.data.ordemColetaLancamento.observacoes.map(
                              (obs) => {
                                  if (obs.dataHoraCriacao)
                                      obs.dataHoraCriacao = format(
                                          new Date(obs.dataHoraCriacao),
                                          'dd/MM/yyyy HH:mm'
                                      );
                                  return obs;
                              }
                          )
                        : []
                );

                setValue('pesagem', {
                    tara: responseOc.data.ordemColetaLancamento.tara,
                    pesoBruto: responseOc.data.ordemColetaLancamento.pesoBruto,
                    pesoLiquido:
                        responseOc.data.ordemColetaLancamento.pesoLiquido,
                });

                setValue(
                    'ordemColetaLancamentoId',
                    responseOc.data.ordemColetaLancamento.id!.toString()
                );

                if (responseOc.data.ordemColetaLancamento.statusDoubleCheck)
                    setValue(
                        'status',
                        responseOc.data.ordemColetaLancamento.statusDoubleCheck
                    );
            }

            let usuarios: IUsuario[] = [];
            responseUsuarios.data.map((usuario: any) =>
                usuarios.push({ login: usuario.login, usuario: usuario.nome })
            );

            setValue('listaUsuarios', usuarios);

            setLoading(false);
        };

        fetchData();

    }, []);

    const salvarDoubleCheck = async () => {
        setLoading(true);

        const o = getValues('observacoes').map((obs) => {
            obs.dataHoraCriacao = null;
            return obs;
        });

        const notas = getValues('notas').map((nota) => {
            nota.dataEmissao = nota.dataEmissao;
            return nota;
        });

        const vinculosSemNota = getValues('vinculosSemNota').map((vinculoDeContratoSemNota) => {
            return vinculoDeContratoSemNota;
        });

        const trocaNotaGo = getValues('trocaDeNotaEstadoDoGoias');

        const ocl: IOrdemColetaLancamentoDoubleCheck = {
            ordemColetaLancamentoId: parseInt(
                getValues('ordemColetaLancamentoId')
            ),
            statusDoubleCheck: getValues('status'),
            observacoes: o,
            classificacaoAmostras: getValues('classificacaoAmostras'),
            notasAnteriores: notas,
            vinculosDeContratosSemNotaCasoTrocaDeNotaGoias: vinculosSemNota,
            trocaDeNotaEstadoDoGoias: trocaNotaGo,
            laudo: getValues('laudo'),
            ticketCaminhaoCarregado: getValues('ticket'),
        };

        try {
            const timeoutDuration = 300000;
        
            const timeout = new Promise<Response>((_, reject) =>
                setTimeout(() => reject(new Error('Request timed out')), timeoutDuration)
            );
        
            const apiCall = servicoOcl.api.put(
                `/double-check/${ocl.ordemColetaLancamentoId}`,
                ocl
            );

            const response = await Promise.race([apiCall, timeout]);
        
            if (response.status === 200) {
                enqueueSnackbar('Double check salvo com sucesso.', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar(`Erro: ${response?.statusText}`, {
                    variant: 'error',
                });
            }
            navigate(FrontEndPathResources.DoubleCheck);
        } catch (e: any) {
            if (e?.message === 'Request timed out') {
                enqueueSnackbar('Erro: Operação excedeu o tempo limite.', {
                    variant: 'error',
                });
            }
            else if (
                e[0]?.atributo === "Registro salvo localmente apenas" &&
                e[0]?.mensagem === "Erro ao enviar os dados de Lançamento para o Maxys ERP"
            ) {        
                enqueueSnackbar('Erro ao emitir nota no maxys. Necessário reprocessar.', {
                    variant: 'warning',
                });
                navigate(FrontEndPathResources.DoubleCheck);
            } else {
                enqueueSnackbar(`Erro Interno: ${e[0]?.atributo} - ${e[0]?.mensagem}`, {
                    variant: 'error',
                });
            }
        } finally {
            setLoading(false)
        }
    };

    return (
        <SpinnerLoading isLoading={loading}>
            <Container maxWidth={false}>
                <FormProvider {...formDoubleCheck}>
                    <PageTitle title={'Double Check'} />
                    <DividerComp texto="Alterar Usuário Responsável" />
                    <UsuarioResponsavelForm />
                    <DividerComp texto="Motorista" />
                    <MotoristaForm />
                    <DividerComp texto="Cavalo" />
                    <CavaloCarretaForm />
                    <DividerComp texto="Ordem Coleta" />
                    <OrdemColetaForm />
                    <DividerComp texto="Embarque" />
                    <EmbarqueForm />
                    <DividerComp texto="Classificação" />
                    <ClassificacaoForm />
                    {/* Importante: se há nota anterior vinculada, segue o processo padrão de troca de nota:
                    */}
                    {watch('notas')?.length > 0 && (
                        <DividerComp texto="Nota(s) Fiscal(s)" />
                    )}
                    <NotaFiscalForm />
                    {/* Importante: se não há nota anterior vinculada & é um caso "trocaNotaGo",
                        será possível vincular contratos sem nota anterior:
                    */}
                    {(watch('notas')?.length === 0) && watch('trocaDeNotaEstadoDoGoias') && (
                        <VinculoContratoSemNotaForm />
                    )}
                    <DividerComp texto="Observações" />
                    <ObservacoesForm />
                    <DividerComp texto="Status" />
                    <StatusForm />
                    <Grid container item spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <Button
                                onClick={() => {
                                    const notas = watch('notas');
                                    const vinculosSemNota = watch('vinculosSemNota');
                                    const trocaDeNotaEstadoDoGoias = watch('trocaDeNotaEstadoDoGoias');
                                    if (trocaDeNotaEstadoDoGoias == true) {
                                        console.log(`trocaDeNotaEstadoDoGoias == true`);
                                        //CASO TROCA DE NOTA GOIÁS - DUAS POSSIBILIDADES: 
                                            if (notas.length > 0 && notas[0].contratos == null) {
                                                //1) HOUVE VÍNCULO DE NOTA(S) NA ETAPA ANTERIOR NORMALMENTE - PRECISA VINCULAR CONTRATOS À(S) NOTA(S)
                                                enqueueSnackbar('Processo Estado do Goiás com nota! Contratos ainda não vinculado(s) à(s) nota(s). Necessário vincular.', {
                                                    variant: 'warning',
                                                });
                                            } else if (notas.length == 0 && vinculosSemNota.length == 0) {
                                                //2) NÃO HOUVE VÍNCULO DE NOTA NA ETAPA ANTERIOR - PRECISA VINCULAR CONTRATOS "sem notas"
                                                enqueueSnackbar('Processo Estado do Goiás sem nota! Contratos ainda não vinculado(s). Necessário vincular.', {
                                                    variant: 'warning',
                                                });
                                            } else {
                                                setOpenModalConfirmacao(true);
                                            }
                                    } else {
                                        console.log(`ELSE >> trocaDeNotaEstadoDoGoias = ${trocaDeNotaEstadoDoGoias}`);
                                        console.log(`notas.length = ${notas.length}`);
                                        console.log(`notas[0].contratos = ${notas[0].contratos}`);
                                        //CASO TROCA DE NOTA PROCESSO PADRÃO - SEM SER ESTADO DO GOIÁS - UMA POSSIBILIDADE: 
                                        if (notas.length > 0 && notas[0].contratos == null) {
                                            //1) HOUVE VÍNCULO DE NOTA(S) NA ETAPA ANTERIOR NORMALMENTE - PRECISA VINCULAR CONTRATOS À(S) NOTA(S)
                                            enqueueSnackbar('Processo Troca de Nota padrão! Contratos ainda não vinculado(s) à(s) nota(s). Necessário vincular.', {
                                                variant: 'warning',
                                            });
                                        } else {
                                            setOpenModalConfirmacao(true);
                                        }
                                    }
                                }}
                                variant="contained"
                                style={styles().button}
                                size="large"
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                    <ConfirmacaoModal
                        open={openModalConfirmacao}
                        setModalOpen={setOpenModalConfirmacao}
                        saveDoubleCheck={salvarDoubleCheck}
                    />
                </FormProvider>
            </Container>
        </SpinnerLoading>
    );
}
