import type { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import type { LotePorTransportadoraDtoResponse } from '../../types/LotePorTransportadoraDtoResponse';
import type { IFindLotesEmbarqueByFilter } from '../dtos/IFindLotesEmbarqueByFilter';

import type { ILotesPorTransportadoraRepository } from '../repositories/ILotesPorTransportadoraRepository';

export class FindLotesByFiltros
    implements IBaseUseCase<IFindLotesEmbarqueByFilter, LotePorTransportadoraDtoResponse[]>
{
    constructor(
        private readonly repository: ILotesPorTransportadoraRepository
    ) {}

    execute(filtro: IFindLotesEmbarqueByFilter): Promise<LotePorTransportadoraDtoResponse[]> {
        return this.repository.findLoteByFiltro(filtro);
    }
}
