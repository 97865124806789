export enum EStatusOrdemColeta {
    AGENDADO = 'Agendado',
    ENVIADO_PARA_DOUBLECHECK = 'Enviado para DoubleCheck',
    PENDENTE_DE_DESCARGA = 'Pendente de Descarga',
    PENDENTE_DE_FATURAMENTO = 'Pendente de Faturamento',
    ENVIADO_MAXYS = 'Enviado Maxys',
    FINALIZADO = 'Finalizado',
    COM_ERRO = 'Com Erro',
    AGUARDANDO_GRE014 = 'Aguardando GRE014',
    FINALIZADO_GRE014 = 'Finalizado GRE014',
    ERRO_TAF027 = 'Com Erro TAF027'
}
