import {
    Alert,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import { ApiResources } from '../../Models/Api';
import type Auditoria from '../../Models/Auditoria';
import { RevisionType } from '../../Models/Auditoria';
import type LoteEmbarque from '../../Models/LoteEmbarque';
import { StatusRequisicao } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';
import { styles } from './helpers/Styles';
import type { IOrdemColetaResponseDto } from '../OrdemColeta/domain/dtos/IOrdemColetaResponseDto';
import useAuditoriaCamposOrdemColeta from './useAuditoriaCamposOrdemColeta';
import { formatarData } from '../../utils/formatarData';
import { formatarPeso } from '../../utils/formatarPeso';

interface Props {
    ordemColeta: IOrdemColetaResponseDto;
    fecharClicado: () => void;
    aberto: boolean;
}

export function ModalHistoricoAlteracoesOrdemColeta({
    ordemColeta,
    fecharClicado,
    aberto = false,
}: Props) {
    const [auditorias, setAuditorias] = useState<Auditoria[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { getService } = useGenericService();

    const isMobile = useMediaQuery('(max-width: 600px)');

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getItensAlterados } = useAuditoriaCamposOrdemColeta();

    useEffect(() => {
        if (aberto) {
            setIsLoading(true);
            const carregarOrdem = async () => {
                const service = getService(ApiResources.OrdemColeta);

                try {
                    const resposta = await service.api.get(
                        `/auditoria/${ordemColeta?.id}`
                    );

                    if (resposta.status === StatusRequisicao.OK) {
                        setAuditorias(resposta.data.content);
                        setIsLoading(false);
                    } else {
                        enqueueSnackbar(
                            'Não foi possível encontrar o registro',
                            {
                                variant: 'error',
                                onClick: () => {
                                    closeSnackbar();
                                },
                            }
                        );
                        setIsLoading(false);
                    }
                } catch (error) {
                    console.log(error);
                    setIsLoading(false);
                }
            };

            carregarOrdem();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aberto, ordemColeta, enqueueSnackbar]);

    const getTypeAuditoria = (revisonType: RevisionType) => {
        switch (revisonType) {
            case RevisionType.Adicionado:
                return 'Criação';
            case RevisionType.Deletado:
                return 'Exclusão';
            case RevisionType.Modificado:
                return 'Modificação';
        }
    };

    const getCorpoAuditorias = () => {
        const elements: React.ReactElement[] = [];
        

        auditorias?.forEach((auditoria, index) => {
            const isPrimeiroRegistro = index === 0;
            const isAtualizacao = index > 0;

            if (
                index > 0 &&
                auditorias[index - 1].revision.revisionType ===
                    RevisionType.Deletado
            ) {
                return;
            }
                elements.push(
                    <Card sx={styles(isMobile).cardAlteracoes}>
                        <CardContent>
                            <Alert
                                variant="outlined"
                                severity="info"
                                style={
                                    styles(auditoria.revision.revisionType)
                                        .alertAlteracoes
                                }
                                icon={false}
                            >
                                <div style={{ alignItems: 'center' }}>
                                   
                                    {isPrimeiroRegistro &&
                                        <div>
                                            <strong>
                                                Número:
                                            </strong>
                                            {` ${auditoria.entidade?.id} `}
                                        </div>
									}
                                    <div>
                                        <strong>Tipo de registro: </strong>
                                        {getTypeAuditoria(
                                            auditoria.revision.revisionType
                                        )}
                                    </div>
                                    {isPrimeiroRegistro &&
                                        <div>
                                            <strong>
                                                Data da criação:
                                            </strong>
                                            {` ${formatarData(auditoria?.entidade?.dataHoraColeta)} `}
                                        </div>
                                    }
                                    <div>
                                    {isAtualizacao && 
                                        <div>
                                            <strong>Data da atualização:</strong>{' '}
                                            {formatarData(
                                                
                                                    auditoria.revision.revisionDate.toString())
                                            }
                                        </div>
                                    }
                                    </div>
                                    {isPrimeiroRegistro && (
										<div>
											<strong>
												Placa
												do
												cavalo:
											</strong>
											{` ${auditoria?.entidade?.cavalo?.placa} `}
										</div>
								    )}
                                    {isPrimeiroRegistro && (
                                        <div>
                                            <strong>Peso de origem:</strong>
                                            {` ${formatarPeso(auditoria?.entidade?.peso)} `}
                                        </div>
                                    )}
                                    {isPrimeiroRegistro && auditoria?.entidade?.ordemColetaValidacaoDescarga && (
                                        <div>
                                            <strong>Peso de destino:</strong>
                                            {` ${formatarPeso(auditoria?.entidade?.ordemColetaValidacaoDescarga?.pesoDestino)} `}
                                        </div>
                                    )}
                                    {isPrimeiroRegistro && (
                                        <div>
                                            <strong>Status:</strong>
                                            {` ${auditoria?.entidade?.statusOrdemColeta} `}
                                        </div>
                                    )}
                                    {isPrimeiroRegistro && (
                                        <div>
                                            <strong>Transportadora:</strong>
                                            {` ${auditoria?.entidade?.nomeTransportadora} `}
                                        </div>
                                    )}

                                    {isPrimeiroRegistro && (
                                        <div>
                                            <strong>Cadastrada via portal:</strong>
                                            {` ${auditoria?.entidade?.cadastradaViaPortal ? 'Sim' : 'Não'} `}
                                        </div>
                                    )}

                                    
                                </div>
                                <strong> Usuário responsável:</strong>
                                {` ${auditoria.revision.usuario.login}`}
                            </Alert>

                            {isAtualizacao &&
                                getItensAlterados(
                                    auditoria.entidade as LoteEmbarque,
                                    auditorias[index - 1]
                                        .entidade as LoteEmbarque
                                )}
                        </CardContent>
                    </Card>
                );
            
        });

        return elements;
    };

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Histórico de alterações da Ordem Coleta
            </DialogTitle>
            <SpinnerLoading isLoading={isLoading}>
                {auditorias.length === 0 ? (
                    <Alert severity="info">Não há alterações registradas</Alert>
                ) : (
                    <DialogContent>{getCorpoAuditorias()}</DialogContent>
                )}
            </SpinnerLoading>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
