import { joiResolver } from '@hookform/resolvers/joi';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import LockIcon from '@mui/icons-material/Lock';
import Settings from '@mui/icons-material/Settings';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import type { MensagemErroRequisicaoApi } from '../../Config/Api';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import type Usuario from '../../Models/Usuario';
import {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import type LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import ModalAnexarLogoEmpresa from './ModalAnexarLogoEmpresa';
import { styles } from './helpers/Styles';
import ADFormFields from './ADFormFields';
import { configuracaoValidation } from './configuracaoValidation';
import CloudUpload from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import ModalAnexarLGPD from './ModalAnexarLGPD';
import { useFetchUseCase } from '../../hooks/useFetchUseCase';
import type { IArquivoEncoded } from '../OrdemColeta/domain/entities/IArquivoEncoded';
import { PostTermoVigenteLGPD } from './domain/useCases/PostTermoVigenteLGPD';
import { LGPDRepository } from './infra/LoteEmbarqueRepository';
import { format } from 'date-fns';
import type Auditoria from '../../Models/Auditoria';
import { processFileDownload } from '../../utils/processarArquivo';
import { valid } from 'joi';

export type CamposFormulario = {
    email_envio: string;
    autenticacaoAd: boolean;
    ldapDominio: string;
    ldapIp: string;
    ldapPorta: string;
    senha_email_envio: string;
    provedor_email_contratante: ProvedorEmail;
    host: string;
    port: string;
    imagemBase64: string | null;
};

export type CamposLGPD = {
    lgpdBase64: string | null;
};

export enum ProvedorEmail {
    Gmail = 'GMAIL',
    Outlook = 'OUTLOOK',
    Outros = 'OUTROS',
}


export default function Configuracao() {
    const [auxImagemBase64, setAuxImagemBase64] = useState<string>();

    const [mostrarModalAnexarImagem, setMostrarModalAnexarImagem] =
        useState<boolean>(false);

    const formCamposFormulario = useForm<CamposFormulario>({
        resolver: joiResolver(configuracaoValidation),
        criteriaMode: 'all',
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        reset,
    } = formCamposFormulario;

    const watchImagemBase64 = watch('imagemBase64');

    const [integracaoAutomatica, setIntegracaoAutomatica] =
        useState<boolean>(false);

    const [desativar_ordens_Portal, setOrdensPortal] = useState<boolean>(false);

    const [vincular_clifor_erp, setVincularCliforErp] =
        useState<boolean>(false);

    const [validarLocatario, setValidarLocatario] =
        useState<boolean>(false);

    const { getService } = useGenericService();

    const [usaTlsEmail, setUsaTlsEmail] = useState<boolean>(false);

    const [provedorEmail, setProvedorEmail] = useState<ProvedorEmail>(
        ProvedorEmail.Gmail
    );

    const [termoVigenteLGPD,setTermoVigenteLGPD] = useState(null);

    const [auditorias, setAuditorias] = useState<Auditoria[]>([]);

    const [mostrarModalAnexarLGPD, setMostrarModalAnexarLGPD] =
        useState<boolean>(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [port, setPort] = useState<string>('');

    const autenticacaoAd = watch('autenticacaoAd');
    const ldapDominioWatch = watch('ldapDominio');
    const ldapIpWatch = watch('ldapIp');
    const ldapPortaWatch = watch('ldapPorta');

    const repository = new LGPDRepository();
    const postTermoVigenteLGPD = new PostTermoVigenteLGPD(repository);

    const { executePromise } = useFetchUseCase();

    function handleVincularCliforErpChange() {
        setVincularCliforErp(prevState => {
            const newState = !prevState;
            if (!newState) {
                setValidarLocatario(false);
            }
            return newState;
        });
    };

    function handleUploadLGPDArquivo() {
        setMostrarModalAnexarLGPD(true);
    }

    const handleBaixarTermosLGPD = async () => {
        const servico = getService(ApiResources.TermosVigentesLGPD);

        try {
            const response = await servico.api.get('/ativo/download', {
                responseType: 'arraybuffer',
            });
            processFileDownload(response.data, response.headers['content-disposition']);

        } catch (error) {
            enqueueSnackbar('Erro ao fazer o download do termo Ativo', {
                variant: 'error',
            });
        }
    };

    async function handleBaixarTermosLGPDByAnexoId (id:string) {
        const servico = getService(ApiResources.TermosVigentesLGPD);

        try {
            const response = await servico.api.get(`/download/${id}`, {
                responseType: 'arraybuffer',
            });

            processFileDownload(response.data, response.headers['content-disposition']);
        } catch (error) {
            enqueueSnackbar('Erro ao fazer o download do termo', {
                variant: 'error',
            });
        }
    };

    async function getTermoVigenteLGPD() {
        const servico = getService(ApiResources.TermosVigentesLGPD);

        executePromise(
            () => servico.api.get("/ativo"),
            async (response) => {
                if(response?.data) {
                    setTermoVigenteLGPD(response?.data);
                    const auditoriaResponse = await servico.api.get(`/auditoria/${response.data.id}`);
                    setAuditorias(auditoriaResponse.data.content);
                }
            },
            (error) => {
                enqueueSnackbar('Erro ao obter o termo vigente LGPD', {
                    variant: 'error',
                });
            }
        );
    }

    function enviarLGPD(arquivo: IArquivoEncoded) {
        executePromise(
            () => {
                return postTermoVigenteLGPD.execute({
                    anexoBase64: arquivo.stringBase64,
                    nomeArquivo: arquivo.nome,
                    tipo: 'LGPD',
                });
            },
            () => {
                setMostrarModalAnexarLGPD(false);
                getTermoVigenteLGPD();
                enqueueSnackbar('Documento anexado com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            },
            (e) => {
                enqueueSnackbar('Erro ao anexar documento', {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            }
        );
    };

    useEffect(() => {
        const loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);
    
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        getTermoVigenteLGPD();
    },[])
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const carregarDados = async () => {
            const servicoConfigGeral = getService(ApiResources.ConfigGeral);
            const servicoConfigEmail = getService(ApiResources.ConfigEmail);

            const respostaConfigGeral = await servicoConfigGeral.api.get('');
            const respostaConfigEmail = await servicoConfigEmail.api.get('');

            if (respostaConfigGeral.status === StatusRequisicao.OK) {
                setIntegracaoAutomatica(
                    respostaConfigGeral.data.integracao_automatica
                );

                setOrdensPortal(
                    respostaConfigGeral.data.desativar_ordens_portal
                );

                setVincularCliforErp(
                    respostaConfigGeral.data.vincular_clifor_erp
                );

                setValidarLocatario(
                    respostaConfigGeral.data.validarLocatario
                );

                if (respostaConfigEmail.status !== StatusRequisicao.OK) {
                    reset({
                        imagemBase64: respostaConfigGeral.data.imagemBase64
                    });
                }
            }

            if (respostaConfigEmail.status === StatusRequisicao.OK) {
                setProvedorEmail(
                    respostaConfigEmail.data
                        .provedor_email_contratante as ProvedorEmail
                );

                setUsaTlsEmail(respostaConfigEmail.data.usa_tls_email);

                setPort(respostaConfigEmail.data.port);

                if (respostaConfigGeral.status !== StatusRequisicao.OK) {
                    reset({
                        email_envio: respostaConfigEmail.data.email_envio,
                        senha_email_envio:
                            respostaConfigEmail.data.senha_email_envio,
                        provedor_email_contratante:
                            respostaConfigEmail.data.provedor_email_contratante,
                        host: respostaConfigEmail.data.host,
                        port: respostaConfigEmail.data.port,
                    });
                }
            }

            if (
                respostaConfigGeral.status === StatusRequisicao.OK &&
                respostaConfigEmail.status === StatusRequisicao.OK
            ) {
                reset({
                    email_envio: respostaConfigEmail.data.email_envio,
                    senha_email_envio:
                        respostaConfigEmail.data.senha_email_envio,
                    provedor_email_contratante:
                        respostaConfigEmail.data.provedor_email_contratante,
                    host: respostaConfigEmail.data.host,
                    port: respostaConfigEmail.data.port,
                    autenticacaoAd: respostaConfigGeral.data.autenticacaoAd,
                    imagemBase64: respostaConfigGeral.data.imagemBase64,
                    ldapDominio: respostaConfigGeral.data.ldapDominio,
                    ldapIp: respostaConfigGeral.data.ldapIp,
                    ldapPorta: respostaConfigGeral.data.ldapPorta
                });
            }
        };

        carregarDados();
    }, [reset]);

    const atualizarImagemStorage = async (imagemBase64: string | null) => {
        const loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const lr = JSON.parse(loginResponse) as LoginResponse;

            lr.imagemBase64 = imagemBase64 ? imagemBase64 : undefined;

            localStorage.removeItem(ChavesArmazenamentoStorage.LOGIN_RESPONSE);

            localStorage.setItem(
                ChavesArmazenamentoStorage.LOGIN_RESPONSE,
                JSON.stringify(lr)
            );
        }
    };

    const tratarDados = handleSubmit(
        async (dadosFormulario: CamposFormulario) => {
            try {
                // Obter os serviços necessários
                const servicoConfigGeral = getService(ApiResources.ConfigGeral);
                const servicoConfigEmail = getService(ApiResources.ConfigEmail);

                // Preparar os dados para as requisições
                const respostaConfigGeral = await servicoConfigGeral.api.post(
                    '',
                    {
                        integracao_automatica: integracaoAutomatica,
                        desativar_ordens_portal: desativar_ordens_Portal,
                        vincular_clifor_erp: vincular_clifor_erp,
                        autenticacaoAd: autenticacaoAd,
                        ldapDominio: ldapDominioWatch || null,
                        ldapIp: ldapIpWatch || null,
                        ldapPorta: ldapPortaWatch || null,
                        imagemBase64: dadosFormulario.imagemBase64 || null,
                        validarLocatario: validarLocatario
                    }
                );

                const respostaConfigEmail = await servicoConfigEmail.api.post(
                    '',
                    {
                        ...dadosFormulario,
                        provedor_email_contratante: provedorEmail,
                        usa_tls_email: usaTlsEmail,
                        port: port.toString(),
                    }
                );

                // Verificar o status das respostas e lidar com o sucesso
                if (
                    respostaConfigGeral.status === StatusRequisicao.OK &&
                    respostaConfigEmail.status === StatusRequisicao.OK
                ) {
                    await atualizarImagemStorage(
                        respostaConfigGeral.data.imagemBase64
                    );

                    enqueueSnackbar(
                        'Configurações Gerais e de Email salvas com sucesso!',
                        {
                            variant: 'success',
                            onClick: () => {
                                closeSnackbar();
                            },
                        }
                    );
                } else {
                    if (respostaConfigGeral.status !== StatusRequisicao.OK) {
                        enqueueSnackbar(
                            'Erro ao salvar Configurações Gerais!',
                            {
                                variant: 'error',
                                onClick: () => {
                                    closeSnackbar();
                                },
                            }
                        );
                    }
                    if (respostaConfigEmail.status !== StatusRequisicao.OK) {
                        enqueueSnackbar(
                            'Erro ao salvar Configurações de Email!',
                            {
                                variant: 'error',
                                onClick: () => {
                                    closeSnackbar();
                                },
                            }
                        );
                    }
                }
            } catch (error) {
                // Lidar com erros e exibir mensagens
                // biome-ignore lint/complexity/noForEach: <explanation>
                (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                    enqueueSnackbar(`${erro.atributo}: ${erro.mensagem}`, {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                });
            }
        }
    );

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.CONFIGURACOES_USUARIO}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Container>
                {mostrarModalAnexarImagem && (
                    <ModalAnexarLogoEmpresa
                        aberto={mostrarModalAnexarImagem}
                        fecharClicado={() => {
                            setMostrarModalAnexarImagem(false);
                        }}
                        enviarAnexoClicado={(imagemBase64: string) => {
                            setValue('imagemBase64', imagemBase64);
                            setAuxImagemBase64(imagemBase64);
                            setMostrarModalAnexarImagem(false);
                        }}
                    />
                )}

                {mostrarModalAnexarLGPD && (
                    <ModalAnexarLGPD
                        aberto={mostrarModalAnexarLGPD}
                        fecharClicado={() => {
                            setMostrarModalAnexarLGPD(false);
                        }}
                        enviarAnexoClicado={enviarLGPD}
                    />
                )}
                <FormProvider {...formCamposFormulario}>

                    <Card sx={styles.cardStyle}>
                        <CardContent>
                            <Grid
                                container
                                spacing={2}
                                style={styles.cardTitle}
                            >
                                <Grid item>
                                    <Settings />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        style={styles.title}
                                    >
                                        Configurações Gerais
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} marginBottom={2}>
                                <Grid item xs={12} lg={10}>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                id="smart-check-box"
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            integracaoAutomatica
                                                        }
                                                        onChange={() =>
                                                            setIntegracaoAutomatica(
                                                                !integracaoAutomatica
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Integração automática"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                id="smart-check-box"
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            desativar_ordens_Portal
                                                        }
                                                        onChange={() =>
                                                            setOrdensPortal(
                                                                !desativar_ordens_Portal
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Desativar, criar e alterar as ordens de carregamento no portal"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                id="smart-check-box"
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            vincular_clifor_erp
                                                        }
                                                        onChange={handleVincularCliforErpChange}
                                                    />
                                                }
                                                label="Permitir vincular clifor do ERP com o usuário"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                id="smart-check-box"
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            validarLocatario
                                                        }
                                                        disabled={!vincular_clifor_erp} //desabilitado se "vincular_clifor_erp" = FALSE
                                                        onChange={() =>
                                                            setValidarLocatario(
                                                                !validarLocatario
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Validar Locatário com Usuário Logado"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    <Controller
                                        name="autenticacaoAd"
                                        control={control}
                                        defaultValue={false}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <FormGroup>
                                                <FormControlLabel
                                                    id="smart-check-box"
                                                    control={
                                                        <Checkbox
                                                            checked={value}
                                                            onChange={onChange}
                                                        />
                                                    }
                                                    label="Autenticação AD"
                                                />
                                            </FormGroup>
                                        )}
                                    />
                                    {autenticacaoAd && <ADFormFields />}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        style={styles.subtitle}
                                    >
                                        Logo da Empresa
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Alert variant="outlined" severity="info">
                                Importe a logo da empresa.{' '}
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={() =>
                                        setMostrarModalAnexarImagem(true)
                                    }
                                    edge="start"
                                    style={styles.cloudIconButton}
                                >
                                    <CloudUploadIcon />
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={() => {
                                        setValue('imagemBase64', null);
                                        setAuxImagemBase64(undefined);
                                    }}
                                    edge="start"
                                    style={styles.deleteIconButton}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <i>
                                    (No aplicativo as alterações de logo serão
                                    efetivadas após a realização de um novo
                                    login)
                                </i>
                            </Alert>
                            {watchImagemBase64 && (
                                <div style={{ marginTop: 10 }}>
                                    <img
                                        src={watchImagemBase64}
                                        style={styles.img}
                                        alt="logo_usuario"
                                    />{' '}
                                </div>
                            )}
                        </CardContent>
                    </Card>

                    <Card sx={styles.cardStyle}>
                        <CardContent>
                            <Grid
                                container
                                spacing={2}
                                style={styles.cardTitle}
                            >
                                <Grid item>
                                    <ForwardToInboxIcon />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        style={styles.title}
                                    >
                                        Configurações de Email
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Alert
                                variant="outlined"
                                severity="info"
                                style={styles.alert}
                            >
                                Informe um email para envio do romaneio para o
                                clifor após o embarque.
                            </Alert>

                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={12}>
                                    <Controller
                                        name="email_envio"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    fullWidth
                                                    label="E-mail de envio"
                                                    error={!!errors.email_envio}
                                                    helperText={
                                                        errors.email_envio
                                                            ? errors.email_envio
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Controller
                                        name="senha_email_envio"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Senha do e-mail de envio"
                                                    type="password"
                                                    id="password"
                                                    autoComplete="current-password"
                                                    error={
                                                        !!errors.senha_email_envio
                                                    }
                                                    helperText={
                                                        errors.senha_email_envio
                                                            ? errors
                                                                  .senha_email_envio
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                    Provedor
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setProvedorEmail(
                                            (event.target as HTMLInputElement)
                                                .value as ProvedorEmail
                                        );
                                    }}
                                    value={provedorEmail}
                                >
                                    <FormControlLabel
                                        value={ProvedorEmail.Gmail}
                                        control={<Radio />}
                                        label="Gmail"
                                    />
                                    <FormControlLabel
                                        value={ProvedorEmail.Outlook}
                                        control={<Radio />}
                                        label="Outlook"
                                    />
                                    <FormControlLabel
                                        value={ProvedorEmail.Outros}
                                        control={<Radio />}
                                        label="Outros"
                                    />
                                </RadioGroup>
                            </FormControl>

                            {provedorEmail === ProvedorEmail.Outros ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                        <Controller
                                            name="host"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Host SMTP"
                                                        error={!!errors.host}
                                                        helperText={
                                                            errors.host
                                                                ? errors.host
                                                                      .message
                                                                : ''
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Controller
                                            name="port"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Porta SMTP"
                                                        type="number"
                                                        value={port}
                                                        onChange={(event) => {
                                                            setPort(
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                        error={!!errors.port}
                                                        helperText={
                                                            errors.port
                                                                ? errors.port
                                                                      .message
                                                                : ''
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <></>
                            )}

                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={10}>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                id="smart-check-box"
                                                control={
                                                    <Checkbox
                                                        checked={usaTlsEmail}
                                                        onChange={() =>
                                                            setUsaTlsEmail(
                                                                !usaTlsEmail
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Usa TLS"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card sx={styles.cardStyle}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                component="h2"
                                gutterBottom
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <LockIcon sx={{ marginRight: 1 }} /> Termo de
                                LGPD
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                {termoVigenteLGPD && (
                                (<Box>
                                    <Typography variant="body1">
                                        Versão atual: {termoVigenteLGPD?.version}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Atualizado em { auditorias[0]?.revision?.revisionDate && format(
                                                    new Date(auditorias[0]?.revision?.revisionDate),
                                                    'dd/MM/Y HH:mm'
                                                    ).toString()}
                                    </Typography>
                                </Box>)
                                )}
                                
                              
                                <Box display="flex" gap={2}>
                                    <Button
                                        variant="contained"
                                        startIcon={<CloudUpload />}
                                        onClick={handleUploadLGPDArquivo}
                                    >
                                        {termoVigenteLGPD ? 'Atualizar termo' : 'Enviar termo LGPD'}
                                    </Button>
                                    {
                                        termoVigenteLGPD && (<Button
                                        variant="outlined"
                                        startIcon={<DownloadIcon />}
                                        onClick={handleBaixarTermosLGPD}
                                    >
                                        Baixar Versão Atual 
                                    </Button>)
                                    }
                                    
                                </Box>
                            </Box>
                        </CardContent>
                        {auditorias && auditorias.length > 0 &&(
                            <Card sx={styles.cardStyle}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Histórico de versões
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Nome do arquivo
                                                    </TableCell>
                                                    <TableCell>
                                                        Data/Hora do upload
                                                    </TableCell>
                                                    <TableCell>Usuário</TableCell>
                                                    <TableCell>Ação</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {auditorias.map((version) => (
                                            <TableRow key={version?.revision?.id}>
                                                <TableCell>{version?.entidade?.anexo?.fileName}</TableCell>
                                                <TableCell>{version?.revision?.revisionDate && format(
                                                    new Date(version?.revision?.revisionDate),
                                                    'dd/MM/Y HH:mm'
                                                    ).toString()}
                                                </TableCell>
                                                <TableCell>{version?.revision?.usuario?.login}</TableCell>
                                                <TableCell>
                                                    {version?.entidade?.anexo?.fmId && (
                                                    <IconButton 
                                                        size="small" 
                                                        aria-label="download" 
                                                        onClick={() => handleBaixarTermosLGPDByAnexoId(version?.entidade?.anexo?.id)}>
                                                        <DownloadIcon />
                                                    </IconButton>
                                                    )}
                                              
                                                </TableCell>
                                            </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>)}
                       
                    </Card>

                    <Grid container spacing={2} justifyContent="end">
                        <Button
                            type="submit"
                            variant="contained"
                            style={styles.button}
                            size="large"
                            onClick={() => tratarDados()}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </FormProvider>
            </Container>
        </PermissionGate>
    );
}
