import { Typography, Link, Box } from '@mui/material';
import LogoMaxicon from '../../Assets/Images/logo_maxicon.png';
import { styles } from './helpers/Style';
import packageJson from '../../../package.json';

export default function Footer() {
    return (
        <Box component='footer' style={styles.boxFooter}>
            <Link
                style={styles.linkFooter}
                underline="hover"
                color="inherit"
                href="https://www.maxiconsystems.com.br/"
                target='_blank'
            >
                <Typography
                    sx={styles.typographyLink}>
                    Versão: {packageJson.version}
                </Typography>
                <img
                    src={LogoMaxicon}
                    alt='Maxicon'
                    style={styles.imgLink}
                />
                <Typography
                    sx={styles.typographyLink}>
                    Easymbark - Todos os direitos reservados. CNPJ 03.298.453/0001-34. MAXICON SISTEMAS LTDA
                </Typography>
            </Link>
        </Box>
    );
}