import { joiResolver } from '@hookform/resolvers/joi';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import {
    Controller,
    FormProvider,
    useForm,
    useFormContext,
} from 'react-hook-form';
import InputMask from 'react-input-mask';
import InputMoeda from '../../../../../Components/Input/InputMoeda';
import { inputMoneyMask } from '../../../../../Helpers/Util';
import useGenericService from '../../../../../hooks/useGenericService';
import { ApiResources } from '../../../../../Models/Api';
import { StatusRequisicao } from '../../../../../Models/Usuario';
import { IOrdemColetaLancamento } from '../../../domain/dtos/IOrdemColetaLancamento';
import { EUnidadesMedida } from '../../../domain/entities/EUnidadesMedida';
import { INota } from '../../../domain/entities/INota';
import { styles } from '../OrdemColetaForm/Styles';
import ModalAnexarDanfe from './components';
import { validacaoNf, validacaoNfe } from './JoiValidators';

export function NotaFiscalInput(trocaNotaGo: boolean) {
    const trocaNotaEstadoDoGoias = trocaNotaGo;
    const formOrdemColetaLancamento = useFormContext<IOrdemColetaLancamento>();

    const [tipoDaNota, setTipoDaNota] = useState('nfe');

    const [mostrarModalAnexarDanfe, setMostrarModalAnexarDanfe] =
        useState(false);

    const [disableNfeFields, setDisableNfeFields] = useState(false);

    const { getService } = useGenericService();

    const [errorBuscarNota, setErrorBuscarNota] = useState<string>();

    const formNota = useForm<INota>({
        criteriaMode: 'all',
        resolver: joiResolver(
            tipoDaNota === 'nfe' ? validacaoNfe : validacaoNf
        ),
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisableNfeFields(false);

        setTipoDaNota((event.target as HTMLInputElement).value);

        formNota.reset({});
    };

    const handleKeyDown = async (event: any) => {
        if (
            (event.key === 'Enter' || event.key === 'Tab') &&
            event.target.value.length === 44
        ) {
            await fetchNota(event);
        } else if (event.key === 'Enter' || event.key === 'Tab') {
            formNota.setError('chaveNota', {
                type: 'custom',
                message: 'O campo requer 44 caracteres',
            });
        }
    };

    const fetchNota = async (event: any) => {
        try {
            const servico = getService(ApiResources.IntegracaoMaxysXML);

            const response: AxiosResponse<INota> = await servico.api.get(
                `/consultar-nota?chaveNota=${event.target.value}`
            );

            const nota = {
                chaveNota: response.data.chaveNota,
                numeroNfe: response.data.numeroNfe,
                serie: response.data.serie,
                peso: response.data.peso,
                unidadeMedida: response.data.unidadeMedida,
                valorTotal: response.data.valorTotal,
                dataEmissao: format(
                    new Date(response.data.dataEmissao),
                    'dd/MM/yyyy'
                ),
            };

            if (response.status === StatusRequisicao.OK) {
                formNota.reset(nota);

                setErrorBuscarNota('');

                setDisableNfeFields(true);

                formNota.clearErrors('chaveNota');
            } else {
                formNota.reset({
                    chaveNota: formNota.getValues('chaveNota'),
                });

                setDisableNfeFields(false);

                setErrorBuscarNota('Chave de acesso não encontrada.');
            }
        } catch (error) {
            formNota.reset({
                chaveNota: formNota.getValues('chaveNota'),
            });

            setDisableNfeFields(false);

            setErrorBuscarNota('Chave de acesso não encontrada.');
        }
    };

    const submitForm = async (dadosFormulario: INota) => {
        const notas: INota[] = [];

        const notasAnteriores =
            formOrdemColetaLancamento.getValues('notasAnteriores');

        if (notasAnteriores === undefined) {
            notas.push(dadosFormulario);
            formOrdemColetaLancamento.setValue('notasAnteriores', notas);
        } else {
            notasAnteriores.push(dadosFormulario);
            formOrdemColetaLancamento.setValue(
                'notasAnteriores',
                notasAnteriores
            );
        }

        formNota.reset({
            chaveNota: '',
        });
    };

    useEffect(() => {
        formNota.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoDaNota]);

    return (
        <>
            <FormControl>
                <FormLabel id="tipoDaNota">Tipo da nota</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="tipoDaNota"
                    name="controlled-radio-buttons-group"
                    value={tipoDaNota}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value="nfe"
                        control={<Radio />}
                        label="NFe"
                    />
                    <FormControlLabel
                        value="nf"
                        control={<Radio />}
                        label="NF"
                    />
                </RadioGroup>
            </FormControl>
            <FormProvider {...formNota}>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={formNota.handleSubmit(submitForm)}
                >
                    <Grid container item spacing={2}>
                        {tipoDaNota === 'nfe' && (
                            <Grid item xs={12} lg={6}>
                                <Controller
                                    name="chaveNota"
                                    control={formNota.control}
                                    defaultValue={''}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <InputMask
                                            mask="99999999999999999999999999999999999999999999"
                                            maskPlaceholder={null}
                                            onChange={onChange}
                                            value={value as string}
                                        >
                                            <TextField
                                                {...field}
                                                margin="normal"
                                                fullWidth
                                                value={value}
                                                label="Chave de acesso"
                                                error={!!error?.message}
                                                helperText={
                                                    error?.message
                                                        ? error?.message
                                                        : ''
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={onChange}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </InputMask>
                                    )}
                                />
                                {errorBuscarNota && (
                                    <p style={styles().mensagemErrorStyle}>
                                        {errorBuscarNota}
                                    </p>
                                )}
                            </Grid>
                        )}
                        {tipoDaNota === 'nf' && (
                            <Grid item xs={12} lg={6}>
                                <Controller
                                    name="numeroNf"
                                    control={formNota.control}
                                    defaultValue={''}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={value}
                                            label="Nota fiscal"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => {
                                                let { value } = event.target;
                                                value = value.slice(0, 9);
                                                const onlyNums = value.replace(
                                                    /[^0-9]/g,
                                                    ''
                                                );
                                                onChange(onlyNums);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        {tipoDaNota === 'nfe' && (
                            <Grid item xs={12} lg={6}>
                                <Controller
                                    name="numeroNfe"
                                    control={formNota.control}
                                    defaultValue={''}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={value}
                                            label="Nota fiscal eletrônica"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => {
                                                let { value } = event.target;
                                                value = value.slice(0, 9);
                                                const onlyNums = value.replace(
                                                    /[^0-9]/g,
                                                    ''
                                                );
                                                onChange(onlyNums);
                                            }}
                                            disabled={disableNfeFields}
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} lg={6}>
                            <Controller
                                name="serie"
                                control={formNota.control}
                                defaultValue={''}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={value}
                                        label="Serie"
                                        error={!!error?.message}
                                        helperText={
                                            error?.message ? error?.message : ''
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(event) => {
                                            let { value } = event.target;
                                            value = value.slice(0, 6);
                                            const onlyNums = value.replace(
                                                /[^0-9]/g,
                                                ''
                                            );
                                            onChange(onlyNums);
                                        }}
                                        disabled={disableNfeFields}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="dataEmissao"
                                control={formNota.control}
                                defaultValue={format(new Date(), 'dd/MM/yyyy')}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <InputMask
                                        mask="99/99/9999"
                                        maskPlaceholder="dd/mm/yyyy"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        disabled={disableNfeFields}
                                    >
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={value}
                                            label="Data"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={onChange}
                                        />
                                    </InputMask>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Controller
                                name="peso"
                                control={formNota.control}
                                defaultValue={0}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={value}
                                        label="Quantidade"
                                        error={!!error?.message}
                                        helperText={
                                            error?.message ? error?.message : ''
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(event) => {
                                            let { value } = event.target;
                                            value = value.slice(0, 15);
                                            const onlyNums = value.replace(
                                                /[^0-9]/g,
                                                ''
                                            );
                                            onChange(onlyNums);
                                        }}
                                        disabled={disableNfeFields}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item lg={2} mt={2}>
                            <Controller
                                name="unidadeMedida"
                                control={formNota.control}
                                defaultValue={EUnidadesMedida.KG}
                                render={({
                                    field: { ref, value, onChange, ...field },
                                    fieldState: { error },
                                }) => (
                                    <FormControl fullWidth>
                                        <InputLabel id="Unidade" shrink>
                                            Unidade *
                                        </InputLabel>
                                        <Select
                                            value={value}
                                            label="Unidade *"
                                            onChange={onChange}
                                            placeholder="Unidade"
                                            disabled={disableNfeFields}
                                        >
                                            {Object.values(EUnidadesMedida).map(
                                                (unidadeMedida) => (
                                                    <MenuItem
                                                        key={unidadeMedida}
                                                        value={unidadeMedida}
                                                    >
                                                        {unidadeMedida}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        {error ? (
                                            <span
                                                style={
                                                    styles().mensagemErrorStyle
                                                }
                                            >
                                                {error}
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="valorTotal"
                                control={formNota.control}
                                defaultValue={0}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <InputMoeda
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={inputMoneyMask(value)}
                                        label="Valor total"
                                        error={!!error?.message}
                                        helperText={
                                            error?.message ? error?.message : ''
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onChange}
                                        disabled={disableNfeFields}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item container xs={12} lg={12} spacing={2}>
                            <Grid item xs={12} lg={5}>
                                <Controller
                                    name="danfe"
                                    control={formNota.control}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            disabled={true}
                                            fullWidth
                                            value={value ? value.nome : ''}
                                            onChange={onChange}
                                            label={
                                                tipoDaNota === 'nfe'
                                                    ? 'Danfe'
                                                    : 'Documento'
                                            }
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Button
                                    onClick={() => {
                                        setMostrarModalAnexarDanfe(true);
                                    }}
                                    variant="contained"
                                    style={styles().button}
                                    size="large"
                                >
                                    {tipoDaNota === 'nfe'
                                        ? 'Anexar DANFe'
                                        : 'Anexar Documento'}
                                </Button>
                            </Grid>
                        </Grid>
                        {mostrarModalAnexarDanfe && (
                            <>
                                <ModalAnexarDanfe
                                    open={mostrarModalAnexarDanfe}
                                    onClose={() =>
                                        setMostrarModalAnexarDanfe(false)
                                    }
                                />
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Button
                            variant="contained"
                            style={styles().button}
                            size="large"
                            type="submit"
                        >
                            Adicionar Nota
                        </Button>
                    </Grid>
                    <Controller
                        name="notasAnteriores"
                        control={formOrdemColetaLancamento.control}
                        render={({
                            field: { ref, onChange, onBlur, value, ...field },
                            fieldState: { error },
                        }) =>
                            trocaNotaEstadoDoGoias || 
                            !formOrdemColetaLancamento.formState.errors.notasAnteriores ? (
                                <></>
                            ) : (
                                <span style={styles().mensagemErrorStyle}>
                                    Necessário adicionar nota
                                </span>
                            )
                        }
                    />
                </form>
            </FormProvider>
        </>
    );
}
