import { CardContent, Grid, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { styles } from './helpers/Styles';
import { CamposFormulario } from './Configuracao';

export default function ADFormFields() {
    const {
        formState: { errors },
        control,
    } = useFormContext<CamposFormulario>();
    return (
        <CardContent>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography
                        variant="h5"
                        component="div"
                        style={styles.title}
                    >
                        Configurações de AD
                    </Typography>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
                <Controller
                    name="ldapDominio"
                    control={control}
                    defaultValue={''}
                    render={({
                        field: { value, onChange, onBlur, ...field },
                        fieldState: { error },
                    }) => {
                        return (
                            <TextField
                                {...field}
                                // inputRef={ref}
                                margin="normal"
                                value={value || ''}
                                onChange={onChange}
                                fullWidth
                                label="Domínio"
                                error={!!errors.ldapDominio}
                                helperText={
                                    errors.ldapDominio
                                        ? errors.ldapDominio.message
                                        : ''
                                }
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <Controller
                    name="ldapIp"
                    control={control}
                    defaultValue={''}
                    render={({
                        field: { value, onBlur,onChange, ...field },
                        fieldState: { error },
                    }) => {
                        return (
                            <TextField
                                {...field}
                                // inputRef={ref}
                                margin="normal"
                                onChange={onChange}
                                value={value || ''}
                                fullWidth
                                label="IP"
                                error={!!errors.ldapIp}
                                helperText={
                                    errors.ldapIp ? errors.ldapIp.message : ''
                                }
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <Controller
                    name="ldapPorta"
                    control={control}
                    defaultValue={''}
                    render={({
                        field: { value,onChange, ...field },
                        fieldState: { error },
                    }) => {
                        return (
                            <TextField
                                {...field}
                                // inputRef={ref}
                                onChange={onChange}
                                inputMode="decimal"
                                margin="normal"
                                fullWidth
                                value={value || ''}
                                label="Porta"
                                error={!!errors.ldapPorta}
                                helperText={
                                    errors.ldapPorta
                                        ? errors.ldapPorta.message
                                        : ''
                                }
                            />
                        );
                    }}
                />
            </Grid>
        </CardContent>
    );
}
