import { parseISO, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function formatarData(revisionDate) {
    // Converte a data em string para um objeto Date
    const data = parseISO(revisionDate.toString());
    
    // Formata a data no formato desejado
    return format(data, 'dd/MM/yyyy HH:mm', { locale: ptBR });
}





