import { joiResolver } from '@hookform/resolvers/joi';
import { DatePicker, DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Alert, Button, Grid, TextField } from '@mui/material';
import { ptBR } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import type { MensagemErroRequisicaoApi } from '../../../../../../src/Config/Api';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import DividerComp from '../../../../../Components/Divider/DividerComp';
import InputCpf from '../../../../../Components/Input/InputCpf';
import InputCpfCnpj, {
    maskCPForCNPJ,
} from '../../../../../Components/Input/InputCpfCnpj';
import InputKG from '../../../../../Components/Input/InputKG';
import InputNumero from '../../../../../Components/Input/InputNumero';
import InputPlaca from '../../../../../Components/Input/InputPlaca';
import SelectUF from '../../../../../Components/Select/SelectUF';
import { unmaskCpfCnpj } from '../../../../../Helpers/Util';
import { useFetchUseCase } from '../../../../../hooks/useFetchUseCase';
import { IFindJuncaoByFilterDto } from '../../../domain/dtos/IFindJuncaoByFilterDtoRequest';
import { IFindJuncaoByFilterDtoResponse } from '../../../domain/dtos/IFindJuncaoByFilterDtoResponse';
import { IOrdemColetaRequestDto } from '../../../domain/dtos/IOrdemColetaRequestDto';
import { FindJuncaoByFilter } from '../../../domain/usecases/FindJuncaoByFilter';
import useGenericService from '../../../../../../src/hooks/useGenericService';
import { validacaoFindJuncao } from './JoiValidators';
import { styles } from './Styles';
import { styles as styleHelper } from '../../../../Configuracao/helpers/Styles';
import { useLocation } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { ApiResources } from '../../../../../Models/Api';

// transgenia é um Enum para fazer um select
// amostras já virão com o lote de embarque e só vai precisar editar o resultado

type OrdemColetaFormProps = {
    disabled: boolean;
    pesoDisponivel: number;
    findJuncaoByFilter: FindJuncaoByFilter;
    isAlterar?: boolean;
    tipoProcesso?: string;
    setBloqueioDivergenciaLocatarioQuarteirizado?: (status: boolean) => void;
};

export function OrdemColetaForm({
    disabled,
    pesoDisponivel,
    findJuncaoByFilter,
    isAlterar = false,
    tipoProcesso = '',
    setBloqueioDivergenciaLocatarioQuarteirizado,
}: OrdemColetaFormProps) {
    const { executePromise } = useFetchUseCase();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const location = useLocation();
    const [state] = useState(location.state as any);
    const { transportadora } = state;
    const [showFormJuncao, setShowFormJuncao] = useState(false);
    const [juncaoNaoEncontrada, setJuncaoNaoEncontrada] = useState(false);
    const [camposDisabled, setCamposDisabled] = useState(false);
    const [isLoadingInformation, setIsLoadingInformation] = useState(false);

    const { control, reset, watch, setValue, getValues, formState: { errors } } =
        useFormContext<IOrdemColetaRequestDto>();
    const formValues = watch();

    // Para ver os erros do formulário para debug
    // useEffect(() => {
    //     console.log('Erros de validação:', errors);
    // }, [errors]);

    const mostrarFormJuncao = showFormJuncao || watch('id');
    const mostrarBotaoConsular = !isAlterar && !disabled;
    const mostrarSessaoLocatario = tipoProcesso == "QUARTEIRIZACAO" || formValues.tipoProcesso == "QUARTEIRIZACAO";

    const { getService } = useGenericService();

    const {
        handleSubmit: handleSubmitFindJuncao,
        setValue: setValueFindJuncao,
        formState: { errors: errorsFindJuncao },
        clearErrors: clearErrorsFindJuncao,
    } = useForm<IFindJuncaoByFilterDto>({
        criteriaMode: 'all',
        resolver: joiResolver(validacaoFindJuncao),
    });

    const onClickFindJuncao = handleSubmitFindJuncao(
        (data: IFindJuncaoByFilterDto) => {
            setIsLoadingInformation(true);
            executePromise(
                () =>
                    findJuncaoByFilter.execute({
                        ...data,
                        cpfMotorista: unmaskCpfCnpj(data.cpfMotorista),
                    }),
                (response: IFindJuncaoByFilterDtoResponse) => {
                    handleSuccess(response);
                    setIsLoadingInformation(false);
                },
                () => {
                    handleFailure();
                    setIsLoadingInformation(false);

                }
            );
        }
    );

    const handleSuccess = (response: IFindJuncaoByFilterDtoResponse) => {
        setShowFormJuncao(true);
        setJuncaoNaoEncontrada(false);
        setCamposDisabled(true);
        resetForm(response);
        setValue('veiculoTerceiro', false);
    };

    const handleFailure = () => {
        setShowFormJuncao(true);
        setJuncaoNaoEncontrada(true);
        setCamposDisabled(false);
        resetForm();
        setValue('veiculoTerceiro', true);
    };

    const showVeiculoTerceiro = (): boolean => {
        const isVeiculoTerceiro = getValues('veiculoTerceiro');
        return isVeiculoTerceiro ? isVeiculoTerceiro : false;
    };

    const resetForm = (response?: IFindJuncaoByFilterDtoResponse) => {
        if (response) {
            reset({
                ...formValues,
                ...response,
                motorista: {
                    ...response.motorista,
                    cpf: maskCPForCNPJ(response.motorista.cpf),
                },
                cavalo: {
                    ...response.cavalo,
                    proprietarioDocumento: maskCPForCNPJ(
                        response.cavalo.proprietarioDocumento
                    ),
                    proprietarioEndereco: {
                        estado: response.cavalo.proprietarioEndereco?.estado,
                        cidade: response.cavalo.proprietarioEndereco?.cidade,
                        cep: response.cavalo.proprietarioEndereco?.cep,
                        logradouro: response.cavalo.proprietarioEndereco?.logradouro,
                        bairro: response.cavalo.proprietarioEndereco?.bairro,
                        numero: response.cavalo.proprietarioEndereco?.numero,
                        complemento: response.cavalo.proprietarioEndereco?.complemento,
                        ibge: response.cavalo.proprietarioEndereco?.ibge,
                        id: response.cavalo.proprietarioEndereco?.id,
                        inscricaoMunicipal: response.cavalo.proprietarioEndereco?.inscricaoMunicipal,
                        inscricaoEstadual: response.cavalo.proprietarioEndereco?.inscricaoEstadual,
                        idMaxy: response.cavalo.proprietarioEndereco?.idMaxy,
                    }
                },
                carretas:
                    response.carretas != null
                        ? response.carretas.map((carreta) => ({
                              ...carreta,
                              proprietarioDocumento: maskCPForCNPJ(
                                  carreta.proprietarioDocumento
                              ),
                              proprietarioEndereco: null
                          }))
                        : [],
            });
        } else {
            reset({
                statusOrdemColeta: formValues.statusOrdemColeta,
                cadastradaViaPortal: formValues.cadastradaViaPortal,
                descricaoItem: formValues.descricaoItem,
                dataHoraColeta: formValues.dataHoraColeta,
                loteEmbarqueTransportadoraId:
                    formValues.loteEmbarqueTransportadoraId,
                peso: formValues.peso,

                motorista: {
                    cpf: formValues.motorista.cpf,
                },
                cavalo: {
                    placa: formValues.cavalo.placa,
                    proprietarioEndereco: {
                        estado: formValues?.cavalo?.proprietarioEndereco?.estado,
                        cidade: formValues?.cavalo?.proprietarioEndereco?.cidade,
                        cep: formValues?.cavalo?.proprietarioEndereco?.cep,
                        logradouro: formValues?.cavalo?.proprietarioEndereco?.logradouro,
                        bairro: formValues?.cavalo?.proprietarioEndereco?.bairro,
                        numero: formValues?.cavalo?.proprietarioEndereco?.numero,
                        complemento: formValues?.cavalo?.proprietarioEndereco?.complemento,
                        ibge: formValues?.cavalo?.proprietarioEndereco?.ibge,
                        id: formValues?.cavalo?.proprietarioEndereco?.id,
                        inscricaoMunicipal: formValues?.cavalo?.proprietarioEndereco?.inscricaoMunicipal,
                        inscricaoEstadual: formValues?.cavalo?.proprietarioEndereco?.inscricaoEstadual,
                        idMaxy: formValues?.cavalo?.proprietarioEndereco?.idMaxy,
                    }
                },
                carretas: [
                    {
                        placa: formValues?.carretas?.[0]?.placa,
                        proprietarioEndereco: null
                    },
                    {
                        placa: formValues?.carretas?.[1]?.placa,
                        proprietarioEndereco: null
                    },
                    {
                        placa: formValues?.carretas?.[2]?.placa,
                        proprietarioEndereco: null
                    },
                ],
                veiculoTerceiro: formValues?.veiculoTerceiro,
            });
        }
    };

    function isDisabledRntrc(): boolean {
        return !juncaoNaoEncontrada;
    }

    const getTransportadora = () => {
        let transportadoraNome = ''

        state.ordemColetas.map(oc => {
            if (oc.id == getValues('id')) {
                console.log(oc)
                transportadoraNome = oc.nomeTransportadora
            }
        })

        return transportadoraNome
    }

    //********************************************************
    //#início: VALIDAR LOCATÁRIO COM USUARIO LOGADO - QUARTEIRIZAÇÃO
    const serviceOrdemColeta = getService(ApiResources.OrdemColeta);

    interface JavaReponse { validacao: boolean, mensagem: string; }

    interface ValidarLocatarioResponse { data: JavaReponse }

    const validarLocatarioQuarteirizado = async (documentoLocatario: string) => {
            try {
                const rValidou: ValidarLocatarioResponse = await serviceOrdemColeta.api.get(`/quarteirizacao/validar-locatario/${documentoLocatario}`);

                if (rValidou.data.validacao === true) {
                    //OBS. alinhamento PO: neste caso não haverá mensagem a ser mostrada ao usuário em tela
                    // enqueueSnackbar(rValidou.data.mensagem, {
                    //     variant: 'success',
                    //     onClick: () => {
                    //         closeSnackbar();
                    //     },
                    // });
                    setBloqueioDivergenciaLocatarioQuarteirizado(false);
                } else {
                    enqueueSnackbar(rValidou.data.mensagem, {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                    setBloqueioDivergenciaLocatarioQuarteirizado(true);
                }

            } catch (error) {
                    enqueueSnackbar(
                        'Não foi possível validar locatário do cavalo com usuário logado!',
                        {
                            variant: 'warning',
                            onClick: () => {
                                closeSnackbar();
                            },
                        }
                    );
            }
    };

    const cavaloProprietarioDocumento = watch('cavalo.proprietarioDocumento');

    useEffect(() => {
        if (cavaloProprietarioDocumento && mostrarSessaoLocatario && mostrarBotaoConsular) {    
            validarLocatarioQuarteirizado(unmaskCpfCnpj(cavaloProprietarioDocumento));
        }
    }, [cavaloProprietarioDocumento]);
    //#fim: VALIDAR LOCATÁRIO COM USUARIO LOGADO - QUARTEIRIZAÇÃO
    //********************************************************

    return (
        <>
        {/* Exibindo as mensagens de erro globais do formulário */}
        {errors && Object.keys(errors).length > 0 && (
                <Grid item xs={12}>
                    <Alert severity="error">
                        Existem erros no formulário. Verifique os campos abaixo.
                    </Alert>
                </Grid>
        )}
            <Grid container spacing={2}>
                <Grid item xs={12} lg={disabled ? 3 : 6}>
                    <Controller
                        name="loteEmbarqueId"
                        control={control}
                        defaultValue={undefined}
                        render={({
                            field: { ref, value, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <TextField
                                    {...field}
                                    inputRef={ref}
                                    margin="normal"
                                    disabled={true}
                                    value={value}
                                    fullWidth
                                    label="Lote de Embarque"
                                    error={!!error?.message}
                                    helperText={
                                        error?.message ? error?.message : ''
                                    }
                                    InputLabelProps={{
                                        shrink: !!value,
                                    }}
                                />
                            );
                        }}
                    />
                </Grid>
                {disabled &&
                    (<Grid item xs={12} lg={disabled ? 3 : 6} style={{ marginTop: '16px' }}>
                        <Controller
                            name="dataHoraColeta"
                        control={control}
                        defaultValue={'' as unknown as Date}
                        render={({
                            field: { ref, onChange, value, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <DateTimePicker
                                    {...field}
                                    value={value}
                                    disabled={disabled}
                                    disablePast
                                    onChange={onChange}
                                    allowSameDateSelection
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            label="Data coleta"
                                            placeholder=""
                                            style={{
                                                color: 'blue',
                                            }}
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            autoComplete="new-password"
                                            fullWidth
                                            required
                                        />
                                    )}
                                />
                            );
                            }}
                     />
                    </Grid>)
                }
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name="descricaoItem"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { ref, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <TextField
                                    {...field}
                                    inputRef={ref}
                                    margin="normal"
                                    disabled={true}
                                    fullWidth
                                    label="Item"
                                    error={!!error?.message}
                                    helperText={
                                        error?.message ? error?.message : ''
                                    }
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name="peso"
                        control={control}
                        defaultValue={undefined}
                        render={({
                            field: { ref, onChange, value, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <InputKG
                                    {...field}
                                    margin="normal"
                                    required
                                    value={value}
                                    fullWidth
                                    label={'Peso'}
                                    onChange={onChange}
                                    disabled={disabled}
                                    error={!!error?.message}
                                    helperText={
                                        error?.message
                                            ? error?.message
                                            : `O peso disponível é de ${pesoDisponivel} KG`
                                    }
                                    InputLabelProps={{
                                        shrink: !!value,
                                    }}
                                />
                            );
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <TextField
                        margin="normal"
                        disabled={true}
                        fullWidth
                        InputLabelProps={{
                                shrink: true,
                        }}
                        value={`${transportadora ? (transportadora?.nome ? transportadora?.nome : transportadora?.nomeFantasia) : getTransportadora()}`}
                        label="Transportadora"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} lg={2}>
                    <Controller
                        name="numeroOcTaf027"
                        control={control}
                        render={({
                            field: { ref, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <TextField
                                    {...field}
                                    inputRef={ref}
                                    margin="normal"
                                    disabled={true}
                                    fullWidth
                                    label="OC ERP (Quarteirização ou Primarização)"
                                    error={!!error?.message}
                                    helperText={
                                        error?.message ? error?.message : ''
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            );
                        }}
                    />
            </Grid>
            <DividerComp texto="Consultar dados da junção" />
            {juncaoNaoEncontrada && showFormJuncao && (
                <p style={styles().mensagemErrorStyle}>
                    Junção não encontrada, preencha os campos abaixo
                </p>
            )}

            {showVeiculoTerceiro() && (
                <Alert variant="outlined" severity="info" style={styleHelper.alert}>
                   Veículo de terceiro
                </Alert>
            )}

            <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name="motorista.cpf"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { ref, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <InputCpf
                                    {...field}
                                    required
                                    inputRef={ref}
                                    value={field.value}
                                    disabled={disabled || isAlterar}
                                    margin="normal"
                                    autoComplete="new-password"
                                    fullWidth
                                    label="CPF Motorista"
                                    error={
                                        !!error?.message ||
                                        !!errorsFindJuncao?.cpfMotorista
                                            ?.message
                                    }
                                    helperText={
                                        error?.message
                                            ? error?.message
                                            : errorsFindJuncao?.cpfMotorista
                                                  ?.message
                                            ? errorsFindJuncao?.cpfMotorista
                                                  ?.message
                                            : ''
                                    }
                                    onChange={(event) => {
                                        field.onChange(event.target.value);
                                        setValueFindJuncao(
                                            'cpfMotorista',
                                            event.target.value
                                        );
                                        clearErrorsFindJuncao('cpfMotorista');
                                    }}
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} lg={3}>
                    <Controller
                        name="cavalo.placa"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { ref, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <InputPlaca
                                    {...field}
                                    inputRef={ref}
                                    value={field.value}
                                    required
                                    margin="normal"
                                    disabled={disabled || isAlterar}
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                        field.onChange(
                                            event.target.value.toUpperCase()
                                        );
                                        setValueFindJuncao(
                                            'placaCavalo',
                                            event.target.value
                                        );
                                        clearErrorsFindJuncao('placaCavalo');
                                    }}
                                    fullWidth
                                    label="Placa Cavalo"
                                    error={
                                        !!error?.message ||
                                        !!errorsFindJuncao?.placaCavalo?.message
                                    }
                                    helperText={
                                        error?.message
                                            ? error?.message
                                            : errorsFindJuncao?.placaCavalo
                                                  ?.message
                                            ? errorsFindJuncao?.placaCavalo
                                                  ?.message
                                            : ''
                                    }
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name="carretas.0.placa"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { ref, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <InputPlaca
                                    {...field}
                                    inputRef={ref}
                                    value={field.value}
                                    margin="normal"
                                    disabled={disabled || isAlterar}
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                        field.onChange(
                                            event.target.value.toUpperCase()
                                        );
                                        setValueFindJuncao(
                                            'placaCarreta1',
                                            event.target.value
                                        );
                                    }}
                                    fullWidth
                                    label="Placa Carreta 1"
                                    error={!!error?.message}
                                    helperText={
                                        error?.message ? error?.message : ''
                                    }
                                />
                            );
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name="carretas.1.placa"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { ref, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <InputPlaca
                                    {...field}
                                    inputRef={ref}
                                    value={field.value}
                                    margin="normal"
                                    disabled={disabled || isAlterar}
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                        field.onChange(
                                            event.target.value.toUpperCase()
                                        );
                                        setValueFindJuncao(
                                            'placaCarreta2',
                                            event.target.value
                                        );
                                    }}
                                    fullWidth
                                    label="Placa Carreta 2"
                                    error={!!error?.message}
                                    helperText={
                                        error?.message ? error?.message : ''
                                    }
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name="carretas.2.placa"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { ref, ...field },
                            fieldState: { error },
                        }) => {
                            return (
                                <InputPlaca
                                    {...field}
                                    inputRef={ref}
                                    value={field.value}
                                    margin="normal"
                                    disabled={disabled || isAlterar}
                                    autoComplete="new-password"
                                    onChange={(event) => {
                                        field.onChange(
                                            event.target.value.toUpperCase()
                                        );
                                        setValueFindJuncao(
                                            'placaCarreta3',
                                            event.target.value
                                        );
                                    }}
                                    fullWidth
                                    label="Placa Carreta 3"
                                    error={!!error?.message}
                                    helperText={
                                        error?.message ? error?.message : ''
                                    }
                                />
                            );
                        }}
                    />
                </Grid>
                {mostrarBotaoConsular && (
                    <Grid item container justifyContent="center" xs={12} lg={3}>
                        <LoadingButton
                            onClick={() => {
                                onClickFindJuncao && onClickFindJuncao();
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                            loading={isLoadingInformation}
                            loadingIndicator={
                                <>
                                    <span style={{ marginRight: '.5rem', color: 'white' }}>
                                        Consultando
                                    </span>
                                    <MoonLoader
                                        color="white"
                                        loading={true}
                                        size={18}
                                    />
                                </>
                            }
                        >
                            Consultar Junção
                        </LoadingButton>
                    </Grid>
                )}
            </Grid>
            {mostrarFormJuncao && (
                <>
                    {mostrarSessaoLocatario && (
                    <>    
                    <DividerComp texto="Locatário do Cavalo" />
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="cavalo.proprietarioDocumento"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            //required
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="CPF/CNPJ do Locatário"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="cavalo.proprietarioEndereco.estado"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            //required
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="UF"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Controller
                                name="cavalo.proprietarioEndereco.cidade"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            //required
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="Cidade"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="cavalo.proprietarioEndereco.cep"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            //required
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="CEP"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Controller
                                name="cavalo.proprietarioEndereco.logradouro"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            //required
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="Logradouro"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="cavalo.proprietarioEndereco.numero"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            //required
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="Número"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Controller
                                name="cavalo.proprietarioEndereco.bairro"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            //required
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="Bairro"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Controller
                                name="cavalo.proprietarioEndereco.complemento"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            //required
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="Complemento"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>                                                                        
                    </Grid>
                    </>
                    )}                
                    <DividerComp texto="Motorista" />
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="motorista.nome"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            required
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="Nome Motorista"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3} style={{ marginTop: '16px' }}>
                            <Controller
                                name="motorista.uf"
                                control={control}
                                defaultValue={''}
                                render={(props) => {
                                    return (
                                        <SelectUF
                                            {...props}
                                            options={[]}
                                            inputRef={props.field.ref}
                                            label="UF Motorista"
                                            required
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            onChange={(_, value) => {
                                                props.field.onChange(value);
                                            }}
                                            error={
                                                !!props?.fieldState?.error
                                                    ?.message
                                            }
                                            helperText={
                                                props?.fieldState?.error
                                                    ?.message
                                                    ? props?.fieldState?.error
                                                          ?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="motorista.cnh"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputNumero
                                            {...field}
                                            required
                                            inputRef={ref}
                                            margin="normal"
                                            maxLength={11}
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="CNH Motorista"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <DividerComp texto="Cavalo" />
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="cavalo.renavam"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputNumero
                                            {...field}
                                            required
                                            inputRef={ref}
                                            margin="normal"
                                            maxLength={11}
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="Renavam"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3} style={{ marginTop: '16px' }}>
                            <Controller
                                name="cavalo.uf"
                                control={control}
                                defaultValue={''}
                                render={(props) => {
                                    return (
                                        <SelectUF
                                            {...props}
                                            options={[]}
                                            inputRef={props.field.ref}
                                            label="UF Cavalo"
                                            required
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            onChange={(_, value) => {
                                                props.field.onChange(value);
                                            }}
                                            error={
                                                !!props?.fieldState?.error
                                                    ?.message
                                            }
                                            helperText={
                                                props?.fieldState?.error
                                                    ?.message
                                                    ? props?.fieldState?.error
                                                          ?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="cavalo.rntrc"
                                defaultValue={''}
                                control={control}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputNumero
                                            {...field}
                                            required
                                            inputRef={ref}
                                            margin="normal"
                                            /*alterando para os casos em que a Juncao vem do Maxys e lá
                                            o dado não foi obrigatório!!!!
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }*/
                                            disabled={isDisabledRntrc()}
                                            fullWidth
                                            maxLength={12}
                                            label="RNTRC"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="cavalo.proprietarioNome"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            required
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="Nome Proprietário/Locatário"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="cavalo.proprietarioDocumento"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputCpfCnpj
                                            {...field}
                                            inputRef={ref}
                                            value={field.value}
                                            margin="normal"
                                            required
                                            autoComplete="new-password"
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            label="CPF/CNPJ Proprietário/Locatário"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <LocalizationProvider
                            locale={ptBR}
                            dateAdapter={AdapterDateFns}
                        >
                            <Grid
                                item
                                xs={12}
                                lg={3}
                                style={{ marginTop: '16px' }}
                            >
                                <Controller
                                    name="cavalo.rntrcDataVencimento"
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => {
                                        return (
                                            <DatePicker
                                                inputFormat="dd/MM/yyyy"
                                                value={field.value || null}
                                                /*alterando para os casos em que a Juncao vem do Maxys e lá
                                                o dado não foi obrigatório!!!!
                                                disabled={
                                                    disabled ||
                                                    camposDisabled ||
                                                    isAlterar
                                                }*/
                                                disabled={isDisabledRntrc()}
                                                onChange={field.onChange}
                                                allowSameDateSelection
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        label="Data de vencimento do RNTRC"
                                                        placeholder=""
                                                        value={
                                                            params.inputProps
                                                                .value || ''
                                                        }
                                                        error={!!error?.message}
                                                        helperText={
                                                            error?.message
                                                                ? error?.message
                                                                : ''
                                                        }
                                                        fullWidth
                                                        required
                                                    />
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                    {Array.from({ length: 3 }).map((_, index) => (
                        <>
                            <DividerComp texto={`Carreta ${index + 1}`} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name={`carretas.${index}.renavam`}
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    disabled={
                                                        disabled ||
                                                        camposDisabled ||
                                                        isAlterar
                                                    }
                                                    maxLength={11}
                                                    fullWidth
                                                    label="Renavam"
                                                    error={!!error?.message}
                                                    helperText={
                                                        error?.message
                                                            ? error?.message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3} style={{ marginTop: '16px' }}>
                            <Controller
                                name={`carretas.${index}.uf`}
                                control={control}
                                defaultValue={''}
                                render={(props) => {
                                    return (
                                        <SelectUF
                                            {...props}
                                            options={[]}
                                            inputRef={props.field.ref}
                                            label="UF Carreta"
                                            required
                                            disabled={
                                                disabled ||
                                                camposDisabled ||
                                                isAlterar
                                            }
                                            fullWidth
                                            onChange={(_, value) => {
                                                props.field.onChange(value);
                                            }}
                                            error={
                                                !!props?.fieldState?.error
                                                    ?.message
                                            }
                                            helperText={
                                                props?.fieldState?.error
                                                    ?.message
                                                    ? props?.fieldState?.error
                                                          ?.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name={`carretas.${index}.rntrc`}
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    /*alterando para os casos em que a Juncao vem do Maxys e lá
                                                    o dado não foi obrigatório!!!!
                                                    disabled={
                                                        disabled ||
                                                        camposDisabled ||
                                                        isAlterar
                                                    }*/
                                                    disabled={isDisabledRntrc()}
                                                    fullWidth
                                                    maxLength={12}
                                                    label="RNTRC"
                                                    error={!!error?.message}
                                                    helperText={
                                                        error?.message
                                                            ? error?.message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3} style={{ marginTop: '16px' }}>
                        </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name={`carretas.${index}.proprietarioNome`}
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    disabled={
                                                        disabled ||
                                                        camposDisabled ||
                                                        isAlterar
                                                    }
                                                    fullWidth
                                                    label="Nome Proprietário/Locatário"
                                                    error={!!error?.message}
                                                    helperText={
                                                        error?.message
                                                            ? error?.message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name={`carretas.${index}.proprietarioDocumento`}
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputCpfCnpj
                                                    {...field}
                                                    inputRef={ref}
                                                    value={field.value}
                                                    margin="normal"
                                                    disabled={
                                                        disabled ||
                                                        camposDisabled ||
                                                        isAlterar
                                                    }
                                                    fullWidth
                                                    label="CPF/CNPJ Proprietário/Locatário"
                                                    error={!!error?.message}
                                                    helperText={
                                                        error?.message
                                                            ? error?.message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <LocalizationProvider
                                    locale={ptBR}
                                    dateAdapter={AdapterDateFns}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        lg={3}
                                        style={{ marginTop: '16px' }}
                                    >
                                        <Controller
                                            name={`carretas.${index}.rntrcDataVencimento`}
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <DatePicker
                                                        inputFormat="dd/MM/yyyy"
                                                        value={
                                                            field.value || null
                                                        }
                                                        disabled={isDisabledRntrc()}
                                                        /*alterando para os casos em que a Juncao vem do Maxys e lá
                                                        o dado não foi obrigatório!!!!
                                                        disabled={
                                                            disabled ||
                                                            camposDisabled ||
                                                            isAlterar
                                                        }*/
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        allowSameDateSelection
                                                        renderInput={(
                                                            params: any
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Data de vencimento do RNTRC"
                                                                value={
                                                                    params
                                                                        .inputProps
                                                                        .value ||
                                                                    ''
                                                                }
                                                                placeholder=""
                                                                error={
                                                                    !!error?.message
                                                                }
                                                                helperText={
                                                                    error?.message
                                                                        ? error?.message
                                                                        : ''
                                                                }
                                                                fullWidth
                                                                required
                                                            />
                                                        )}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                        </>
                    ))}
                </>
            )}
        </>
    );
}
