import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Divider, List, ListItem, ListItemText } from '@mui/material';
import type LoteEmbarqueTransportadora from '../../../Models/LoteEmbarqueTransportadora';
import { styles } from '../helpers/Styles';

interface Props {
    loteEmbarqueTransportadoraAtual: LoteEmbarqueTransportadora;
    loteEmbarqueTransportadoraAnterior: LoteEmbarqueTransportadora;
}
export function ItensAlterados({
    loteEmbarqueTransportadoraAtual,
    loteEmbarqueTransportadoraAnterior,
}: Props) {
    return (
        <List sx={styles().listAuditoria}>
            {loteEmbarqueTransportadoraAtual.pesoPlanejado !==
                loteEmbarqueTransportadoraAnterior.pesoPlanejado && (
                <ListItem>
                    <ListItemText
                        primary="Peso planejado"
                        secondary={
                            <div style={styles().divListItemText}>
                                {loteEmbarqueTransportadoraAnterior.pesoPlanejado} KG{' '}
                                <ArrowForwardIcon
                                    fontSize="small"
                                    style={styles().arrowForwardIcon}
                                />{' '}
                                {loteEmbarqueTransportadoraAtual.pesoPlanejado} KG
                            </div>
                        }
                    />
                </ListItem>
            )}
            {loteEmbarqueTransportadoraAtual.pesoAgendado !==
                loteEmbarqueTransportadoraAnterior.pesoAgendado && (
                <ListItem>
                    <ListItemText
                        primary="Peso agendado"
                        secondary={
                            <div style={styles().divListItemText}>
                                {loteEmbarqueTransportadoraAnterior.pesoAgendado} KG{' '}
                                <ArrowForwardIcon
                                    fontSize="small"
                                    style={styles().arrowForwardIcon}
                                />{' '}
                                {loteEmbarqueTransportadoraAtual.pesoAgendado} KG
                            </div>
                        }
                    />
                </ListItem>
            )}
            {loteEmbarqueTransportadoraAtual.saldo !==
                loteEmbarqueTransportadoraAnterior.saldo && (
                <ListItem>
                    <ListItemText
                        primary="Saldo"
                        secondary={
                            <div style={styles().divListItemText}>
                                {loteEmbarqueTransportadoraAnterior.saldo} KG

                                <ArrowForwardIcon
                                    fontSize="small"
                                    style={styles().arrowForwardIcon}
                                />{' '}
                                {loteEmbarqueTransportadoraAtual.saldo} KG{' '}

                            </div>
                        }
                    />
                </ListItem>
            )}


            {loteEmbarqueTransportadoraAnterior.status !==
                loteEmbarqueTransportadoraAtual.status && (
                <>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                        <ListItemText
                            primary="Local da classificação"
                            secondary={
                                <div style={styles().divListItemText}>
                                    {loteEmbarqueTransportadoraAnterior.status}{' '}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {loteEmbarqueTransportadoraAtual.status}{' '}
                                </div>
                            }
                        />
                    </ListItem>
                </>
            )}
        </List>
    );
}
