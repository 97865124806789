import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import type LoteEmbarqueTransportadora from '../../../Models/LoteEmbarqueTransportadora';
import { CorpoAuditoria } from './CorpoAuditoria';

interface Props {
    loteEmbarqueTransportadora: LoteEmbarqueTransportadora;
    fecharClicado: () => void;
    aberto: boolean;
}

export interface OrdemColetaResumoDto {
    id: number;
    statusOrdemColeta: string;
    cadastradaViaPortal: boolean;
    numeroLoteGpe65: number;
    dataHoraColeta: string;
    placaVeiculo: string;
    descricaoItem: string;
    peso: number;
    usuarioResponsavelPelaDelecao: string;
}

export default function ModalHistoricoAlteracoes({
    loteEmbarqueTransportadora,
    fecharClicado,
    aberto = false,
}: Props) {
    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Histórico de alterações do lote
            </DialogTitle>
            <DialogContent>
                {loteEmbarqueTransportadora?.id &&
                    loteEmbarqueTransportadora.id && (
                        <CorpoAuditoria
                            loteEmbarqueTransportadoraId={
                                loteEmbarqueTransportadora.id
                            }
                        />
                    )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
