import { joiResolver } from '@hookform/resolvers/joi';
import { Button, Container, Divider, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import { unmaskCpfCnpj } from '../../../Helpers/Util';
import { useFetchUseCase } from '../../../hooks/useFetchUseCase';
import { FrontEndPathResources } from '../../../Models/Api';
import { IOrdemColetaRequestDto } from '../domain/dtos/IOrdemColetaRequestDto';
import { EStatusOrdemColeta } from '../domain/entities/EStatusOrdemColeta';
import { FindJuncaoByFilter } from '../domain/usecases/FindJuncaoByFilter';
import { GetSaldoLoteEmbarqueTransportadoraById } from '../domain/usecases/GetSaldoLoteEmbarqueTransportadoraById';
import { PostOrdemColeta } from '../domain/usecases/PostOrdemColeta';
import { OrdemColetaForm } from './components/OrdemColetaForm';
import { validacao } from './components/OrdemColetaForm/JoiValidators';
import { styles } from './components/OrdemColetaForm/Styles';

interface Props {
    postOrdemColeta: PostOrdemColeta;
    getSaldoLoteEmbarqueTransportadoraById: GetSaldoLoteEmbarqueTransportadoraById;
    findJuncaoByFilter: FindJuncaoByFilter;
    bloqueioDivergenciaLocatarioQuarteirizado: boolean;
}

export default function Criar({
    postOrdemColeta,
    getSaldoLoteEmbarqueTransportadoraById,
    findJuncaoByFilter,
    bloqueioDivergenciaLocatarioQuarteirizado,
}: Props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { executePromise, loading } = useFetchUseCase();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { tipoProcesso } = useParams();
    const [state] = useState(location.state as any);
    const { status, lote, produto, id } = state;
    const [pesoDisponivel, setPesoDisponivel] = useState<number>(0);

    const [bloqueioLocatario, setBloqueioLocatario] = useState<boolean>(bloqueioDivergenciaLocatarioQuarteirizado);

    // Para conferir o valor do bloqueioLocatario quando ele mudar
    // useEffect(() => {
    //     console.log('Estado de bloqueioLocatario alterado:', bloqueioLocatario);
    // }, [bloqueioLocatario]);

    const ordemColetaForm = useForm<IOrdemColetaRequestDto>({
        criteriaMode: 'all',
        resolver: joiResolver(validacao(pesoDisponivel)),
    });

    const { handleSubmit, reset } = ordemColetaForm;

    const onSubmit = handleSubmit(async (data: IOrdemColetaRequestDto) => {
        const isArrayCarretaNotEmpty = data.carretas?.some(
            (carreta) => carreta.placa !== ''
        );

        if (isArrayCarretaNotEmpty) {
            const carretas = data.carretas?.filter(
                (carreta) => carreta.placa !== ''
            );
            data.carretas = carretas;
        } else {
            data.carretas = [];
        }

        const dataTratada: IOrdemColetaRequestDto = {
            ...data,
            statusOrdemColeta: EStatusOrdemColeta.AGENDADO,
            motorista: {
                ...data.motorista,
                cpf: unmaskCpfCnpj(data.motorista.cpf),
            },
            cavalo: {
                ...data.cavalo,
                proprietarioDocumento: unmaskCpfCnpj(
                    data.cavalo.proprietarioDocumento
                ),
                proprietarioEndereco: data.cavalo.proprietarioEndereco
            },
            dataHoraColeta: new Date().toISOString(),
            carretas: [
                ...(data.carretas != null
                    ? data.carretas.map((carreta) => ({
                          ...carreta,
                          proprietarioDocumento: unmaskCpfCnpj(
                              carreta.proprietarioDocumento
                          ),
                      }))
                    : []),
            ],
        };

        executePromise(
            () => postOrdemColeta.execute(dataTratada),
            () => {
                enqueueSnackbar('Salvo com sucesso!', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                navigate(FrontEndPathResources.LoteEmbarquePorTransportadora);
            },
            (errorResponse) => {
                const erroNoEnvioProErpMaxys = errorResponse.find(
                    (error: { atributo: string; mensagem: string }) =>
                        error.atributo === 'EnvioMaxysERP' &&
                        error.mensagem.includes(
                            'OrdemColeta não integrada com o Maxys. Necessário reprocessar mais tarde.'
                        )
                );

                if (erroNoEnvioProErpMaxys) {
                    enqueueSnackbar('Registro salvo com sucesso!', {
                        variant: 'success',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                    enqueueSnackbar(
                        'Atenção: ' + erroNoEnvioProErpMaxys.mensagem,
                        {
                            variant: 'warning',
                            onClick: () => {
                                closeSnackbar();
                            },
                        }
                    );
                    navigate(
                        FrontEndPathResources.LoteEmbarquePorTransportadora
                    );
                } else {
                    const outroErroGenerico = errorResponse
                        .map(
                            (error: { atributo: string; mensagem: string }) =>
                                error.mensagem
                        )
                        .join(' | ');
                    enqueueSnackbar('Erro: ' + outroErroGenerico, {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            }
        );
    });

    useEffect(() => {
        executePromise(
            () => getSaldoLoteEmbarqueTransportadoraById.execute(id),
            (response) => {
                setPesoDisponivel(response);
            },
            () => {
                enqueueSnackbar(
                    'Saldo de peso para o Lote Embarque Transpordora não encontrado',
                    {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    }
                );
            }
        );

        reset({
            loteEmbarqueId: lote,
            statusOrdemColeta: status,
            descricaoItem: produto?.nome,
            loteEmbarqueTransportadoraId: id,
            cadastradaViaPortal: true,
            tipoProcesso: tipoProcesso
        });
    }, [
        status,
        lote,
        tipoProcesso,
        produto,
        reset,
        id,
        executePromise,
        getSaldoLoteEmbarqueTransportadoraById,
        enqueueSnackbar,
        closeSnackbar,
    ]);

    return (
        <SpinnerLoading isLoading={loading}>
            <Container>
                <FormProvider {...ordemColetaForm}>
                    <PageTitle title={'Cadastrar ordem de coleta'} />
                    <Divider />

                    <OrdemColetaForm
                        disabled={false}
                        pesoDisponivel={pesoDisponivel}
                        findJuncaoByFilter={findJuncaoByFilter}
                        tipoProcesso={tipoProcesso}
                        setBloqueioDivergenciaLocatarioQuarteirizado={setBloqueioLocatario} // Atualizando o estado
                    />
                    <Grid container spacing={2} justifyContent="end">
                        <Button
                            onClick={() => onSubmit()}
                            variant="contained"
                            style={styles().button}
                            size="large"
                            disabled={bloqueioLocatario || !ordemColetaForm.formState.isValid}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </FormProvider>
            </Container>
        </SpinnerLoading>
    );
}
