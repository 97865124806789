import type { AxiosResponse } from 'axios';
import type { IBaseUseCase } from '../../../types/interfaces/IBaseUseCase';
import type { IOrdemColetaRepository } from '../../OrdemColeta/domain/repositories/IOrdemColetaRepository';
import type { IFindOrdemColetaDoubleCheckByFilter } from '../dtos/IFindOrdemColetaDoubleCheckByFilter';


export class FindOrdemColetaDoubleCheckByFiltros
    implements IBaseUseCase<IFindOrdemColetaDoubleCheckByFilter, AxiosResponse>
{
    constructor(
        private readonly repository: IOrdemColetaRepository
    ) {}

    execute(filtro: IFindOrdemColetaDoubleCheckByFilter): Promise<AxiosResponse> {
        return this.repository.findOrdemColetaDoubleCheckByFiltros(filtro);
    }
}
