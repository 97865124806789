import { Button, Grid, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { styles } from '../../PerfilUsuario/helpers/Styles';
import { IDoubleCheck } from '../entities/IDoubleCheck';

interface ClassificacaoProps {}

const Classificacao: FunctionComponent<ClassificacaoProps> = () => {
    const { control, setValue, reset, getValues } =
        useFormContext<IDoubleCheck>();

    const { fields, append, prepend, remove, swap, move, insert, replace } =
        useFieldArray({
            control,
            name: 'classificacaoAmostras',
        });

    return (
        <>
            {fields.map((item, index) => {
                return (
                    <Grid container item spacing={2} key={item.id}>
                        <Grid item xs={12} lg={4}>
                            <Controller
                                name={`classificacaoAmostras.${index}.descricao`}
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={value}
                                        label="Descrição"
                                        error={!!error?.message}
                                        helperText={
                                            error?.message ? error?.message : ''
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} lg={1}>
                            <Controller
                                name={`classificacaoAmostras.${index}.resultado`}
                                control={control}
                                // defaultValue={0}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={value}
                                        label="Resultado"
                                        error={!!error?.message}
                                        helperText={
                                            error?.message ? error?.message : ''
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => {
                                            let formattedValue = e.target.value;

                                            if (formattedValue) {
                                                
                                                if (formattedValue.includes(',')) {
                                                    formattedValue = formattedValue.replace(',', '.');
                                                }

                                                formattedValue = formattedValue.replace(
                                                    /^(\d+(\.\d{0,1})?).*$/, '$1'
                                                );
                                            }

                                            const parsedValue = parseFloat(formattedValue);
                                            onChange(!isNaN(parsedValue) ? parsedValue : '');
                                        }}
                                        onBlur={onBlur}
                                        type="number"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};

export default Classificacao;
