import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Chip,
    Collapse,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Select,
    TableSortLabel,
    Tooltip,
    useMediaQuery,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { useNavigate } from 'react-router';
import Service, { FilterParam } from '../../Config/Service';
import Search from './Search';
import { options } from 'joi';
import SpinnerLoading from '../SpinnerLoading/SpinnerLoading';
import { useFetchUseCase } from '../../hooks/useFetchUseCase';

interface Props {
    columns: ComplexColumn[];
    createButtonText: string;
    linkCreateButtonText: string;
    service: Service;
    columnToSearch: string;
    defaultOrder?: string;
    expandedRows?: boolean;
    rows?: any[];
    naoMostrarBotaoNovo?: boolean;
    naoMostrarCampoPesquisar?: boolean;
    naoMostrarRodape?: boolean;
    mostrarIconeItensExcluidos?: boolean;
    paramsFilter?: FilterParam[];
    contentExpandedRow?: (row: any) => JSX.Element;
    onIconeItensExcluidosClicado?: () => void;
    setRowsPerPageCustom?: any;
    setPageCustom?: any;
    totalCustom?: number;
    setSortCustom?: any;
    setOrderByCustom?: any;
    usarComponentesCustom?: boolean;
    filtroCustomAttribute?: string;
    filtroCustomOptions?: string[];
    validarCriar?: () => Promise<void>;
    validarRestaurar?: ()=>Promise<void>;
}

export interface ComplexColumn {
    id: string;
    attribute: string;
    label: string;
    width?: number;
    minWidth?: number;
    align?: 'right' | 'left' | 'center';
    sortable?: boolean;
    format?: (value: any) => React.ReactNode;
}

type Order = 'asc' | 'desc';

export default function GenericComplexTable({
    columns,
    createButtonText,
    linkCreateButtonText,
    service,
    defaultOrder = 'id',
    columnToSearch,
    expandedRows = false,
    rows,
    naoMostrarBotaoNovo,
    naoMostrarCampoPesquisar,
    naoMostrarRodape,
    contentExpandedRow,
    mostrarIconeItensExcluidos = false,
    paramsFilter,
    onIconeItensExcluidosClicado,
    setRowsPerPageCustom,
    setPageCustom,
    totalCustom = 0,
    setSortCustom,
    setOrderByCustom,
    usarComponentesCustom = false,
    filtroCustomAttribute,
    filtroCustomOptions,
    validarCriar,
    validarRestaurar
}: Props) {
    const [order, setOrder] = React.useState<Order>('desc');
    const [page, setPage] = React.useState(0);
    const [orderBy, setOrderBy] = React.useState<string>(defaultOrder);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tableRows, setTableRows] = React.useState<any>([]);
    const [textSearch, setTextSearch] = React.useState<string>('');
    const [totalElements, setTotalElements] = React.useState<number>(0);
    const [selectedCustomFilter, setSelectedCustomFilter] = React.useState('');

    const { executePromise, loading } = useFetchUseCase();

    const navigate = useNavigate();

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        if (usarComponentesCustom) setPageCustom(newPage);
    };

    const isMobile = useMediaQuery('(max-width:600px)');

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        if (usarComponentesCustom) setRowsPerPageCustom(+event.target.value);
        setPage(0);
        if (usarComponentesCustom) setPageCustom(0);
    };

    React.useEffect(() => {
        const carregarLinhas = async () => {
            try {
                let filtros: FilterParam[] = [];

                if (paramsFilter) {
                    filtros = paramsFilter;
                }

                if (textSearch.length > 0) {
                    filtros.push({
                        attribute: 'q',
                        value: textSearch,
                    });
                } else {
                    filtros = filtros.filter(
                        (filtro) => filtro.attribute !== 'q'
                    );
                }

                filtros.push(
                    {
                        attribute: 'sort',
                        value: orderBy + ',' + order,
                    },
                    {
                        attribute: 'page',
                        value: page.toString(),
                    },
                    {
                        attribute: 'size',
                        value: rowsPerPage.toString(),
                    }
                );

                if (filtroCustomAttribute && selectedCustomFilter.length > 0) {
                    filtros.push({
                        attribute: filtroCustomAttribute,
                        value: selectedCustomFilter,
                    });
                }
                executePromise(
                    () => service.getAllWithParams(filtros),
                    (response) => { 
                        setTableRows(response?.data?.content);
                        setTotalElements(response?.data?.totalElements);
                    },
                    () => { },
                )
            } catch (error) {}
        };

        if (naoMostrarCampoPesquisar) {
            setTableRows(rows);
            return;
        }

        if (rows) {
            setTableRows(rows);
        } else {
            carregarLinhas();
        }
    }, [
        naoMostrarCampoPesquisar,
        textSearch,
        orderBy,
        order,
        columnToSearch,
        service,
        rows,
        page,
        rowsPerPage,
        paramsFilter,
        usarComponentesCustom,
        selectedCustomFilter,
    ]);

    const createSortHandler = (attribute: string) => {
        setOrder(order === 'asc' ? 'desc' : 'asc');
        setOrderBy(attribute);
        if (usarComponentesCustom) {
            setSortCustom(order);
            setOrderByCustom(attribute);
        }
    };

    return (
        <SpinnerLoading isLoading={loading}>
            <Grid container spacing={2} marginTop={1} marginBottom={1}>
                {!naoMostrarBotaoNovo && (
                    <Grid item>
                        <Button
                            id="cadastrar-novo"
                            variant="outlined"
                            size="large"
                            style={{ color: '#465f75' }}
                            onClick={ validarCriar != undefined ? validarCriar : () => {
                                navigate(linkCreateButtonText);
                            }}
                            data-id="createButtonTable"
                        >
                            {' '}
                            <AddIcon /> {createButtonText}
                        </Button>
                    </Grid>
                )}

                {mostrarIconeItensExcluidos && (
                    <Grid item>
                        <Tooltip
                            title="Itens Excluídos"
                            placement="top"
                            disableInteractive
                            style={{}}
                        >
                            <IconButton
                                aria-label="ver"
                                onClick={ validarRestaurar != undefined ? 
                                    validarRestaurar :
                                    onIconeItensExcluidosClicado
                                }
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}

                {!naoMostrarCampoPesquisar && (
                    <Grid item>
                        <Search
                            onSearch={(searchText) => {
                                setTextSearch(searchText);
                            }}
                        />
                    </Grid>
                )}

                {filtroCustomAttribute && filtroCustomOptions && (
                    <Grid item xs={12} lg={2}>
                        <FormControl fullWidth>
                            <InputLabel id="custom-select-label">{`Selecione o ${filtroCustomAttribute}`}</InputLabel>
                            <Select
                                labelId="custom-select-label"
                                id="custom-select"
                                value={selectedCustomFilter}
                                label={`Selecione o ${filtroCustomAttribute}`}
                                onChange={(event) => {
                                    setSelectedCustomFilter(event.target.value);
                                }}
                            >
                                <MenuItem key={-1} value={''}>
                                    {'Todos'}
                                </MenuItem>
                                {filtroCustomOptions.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                {isMobile ? (
                    <div>
                        {tableRows.map((tableRow: any, index: number) => {
                            return (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div style={{ display: 'block' }}>
                                            <Chip
                                                label={
                                                    <div>
                                                        <strong>
                                                            {columns[0].label +
                                                                ': '}
                                                        </strong>
                                                        {
                                                            tableRow[
                                                                columns[0]
                                                                    .attribute
                                                            ]
                                                        }
                                                    </div>
                                                }
                                                variant="outlined"
                                            />
                                            <Chip
                                                label={
                                                    <div>
                                                        <strong>
                                                            {columns[1].label +
                                                                ': '}
                                                        </strong>
                                                        {
                                                            tableRow[
                                                                columns[1]
                                                                    .attribute
                                                            ]
                                                        }
                                                    </div>
                                                }
                                                variant="outlined"
                                            />
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            {columns.map((column, index) => {
                                                if (
                                                    index > 1 &&
                                                    column.id !== 'acao'
                                                ) {
                                                    return (
                                                        <ListItem
                                                            disablePadding
                                                        >
                                                            <ListItemButton>
                                                                <ListItemText
                                                                    primary={
                                                                        <div>
                                                                            <strong>
                                                                                {
                                                                                    column.label
                                                                                }

                                                                                :
                                                                            </strong>
                                                                            {column.format
                                                                                ? column.format(
                                                                                      tableRow
                                                                                  )
                                                                                : tableRow[
                                                                                      column
                                                                                          .attribute
                                                                                  ]}
                                                                        </div>
                                                                    }
                                                                />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    );
                                                } else {
                                                    if (column.id === 'acao') {
                                                        return (
                                                            column.format && (
                                                                <TableCell
                                                                    key={
                                                                        column.id
                                                                    }
                                                                    align={
                                                                        column.align
                                                                    }
                                                                    width={
                                                                        column.width
                                                                    }
                                                                >
                                                                    {column.format
                                                                        ? column.format(
                                                                              tableRow
                                                                          )
                                                                        : undefined}
                                                                </TableCell>
                                                            )
                                                        );
                                                    }
                                                }
                                                return null;
                                            })}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </div>
                ) : (
                    <TableContainer sx={{ maxHeight: 600 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {expandedRows && (
                                        <TableCell
                                            style={{
                                                backgroundColor: '#EDF0FE',
                                            }}
                                        />
                                    )}
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                backgroundColor: '#EDF0FE',
                                                fontWeight: 'bold',
                                            }}
                                            sortDirection={
                                                orderBy === column.id
                                                    ? order
                                                    : false
                                            }
                                        >
                                            {column.sortable ? (
                                                <TableSortLabel
                                                    active={
                                                        orderBy === column.id
                                                    }
                                                    direction={
                                                        orderBy === column.id
                                                            ? order
                                                            : 'asc'
                                                    }
                                                    onClick={() =>
                                                        createSortHandler(
                                                            column.attribute
                                                        )
                                                    }
                                                >
                                                    {column.label}
                                                    {orderBy === column.id ? (
                                                        <Box
                                                            component="span"
                                                            sx={visuallyHidden}
                                                        >
                                                            {order === 'desc'
                                                                ? 'sorted descending'
                                                                : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            ) : (
                                                column.label
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows?.map((row: any) => {
                                    return (
                                        <RowBody
                                            key={row.id}
                                            columns={columns}
                                            expandedRow={expandedRows}
                                            row={row}
                                            contentExpandedRow={
                                                contentExpandedRow
                                            }
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {!naoMostrarRodape && (
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={
                            usarComponentesCustom ? totalCustom : totalElements
                        }
                        rowsPerPage={rowsPerPage}
                        labelRowsPerPage={'Linhas por página'}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Paper>
        </SpinnerLoading>
    );
}

function RowBody({
    columns,
    expandedRow = false,
    row,
    contentExpandedRow,
}: {
    expandedRow: boolean;
    columns: ComplexColumn[];
    row: any;
    contentExpandedRow?: (row: any) => JSX.Element;
}) {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                {expandedRow && (
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </TableCell>
                )}

                {columns.map((column) => {
                    const value = row[column.attribute];
                    return (
                        <TableCell
                            key={column.id}
                            align={column.align}
                            width={column.width}
                        >
                            {column.format ? column.format(row) : value}
                        </TableCell>
                    );
                })}
            </TableRow>
            {expandedRow && (
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={12}
                    >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {contentExpandedRow && contentExpandedRow(row)}
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}
